import { Box, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { MedicalHistoryAndPriorTreatment as MedicalHistoryAndPriorTreatmentDto, ProtocolDeviation, Comment, MedicalHistory, ConcomitantMedication, SurgicalProcedure } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from './input/display/IfElseDisplay';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';
import { DateTime } from 'luxon';
import FormDates from '../../components/form/FormDates';

interface IMedicalHistoryAndPriorTreatmentProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2),
        color: theme.palette.secondary.main
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const medicalHistoryColumns: IGroupedSubformGridCell<MedicalHistory>[] = [
    {
        name: 'medicalCondition',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.medicalCondition
    },
    {
        name: 'medicalConditionGrade',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.medicalConditionGrade
    },
    {
        name: 'currentAtRegistration',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.currentAtRegistration
    },
    {
        name: 'dateOfDiagnosis',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.dateOfDiagnosis ? DateTime.fromFormat(row.dateOfDiagnosis.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    },
    {
        name: 'treatmentOngoing',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.treatmentOngoing
    }
];

const conMedsColumns: IGroupedSubformGridCell<ConcomitantMedication>[] = [
    {
        name: 'genericName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.genericName
    },
    {
        name: 'indication',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="indicationSpec"
                reasonPrependParentName
                reasonTableCell
            />
        ),
        sortValue: (row) => row?.indication
    },
    {
        name: 'startDate',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => !!row?.startDate ? DateTime.fromFormat(row.startDate.replaceAll("UK", "01"), "dd/MM/yyyy").toISO() : undefined
    }
];

const surgicalProceduresColumns: IGroupedSubformGridCell<SurgicalProcedure>[] = [
    {
        name: 'procedure',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.procedure
    },
    {
        name: 'laterality',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: (row) => row?.laterality
    },
    {
        name: 'dateOfProcedure',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: (row) => row?.dateOfProcedure
    }
];

const conditionSubscription = { value: true };

const hasNoMedicalHistory: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.medicalHistory || value?.medicalHistory?.length === 0;
const hasMedicalHistory: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.medicalHistory && value?.medicalHistory?.length > 0;

const hasNoConMeds: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.concomitantMedications || value?.concomitantMedications?.length === 0;
const hasConMeds: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.concomitantMedications && value?.concomitantMedications?.length > 0;

const hasNoSurgicalProcedures: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !value?.surgicalProcedures || value?.surgicalProcedures?.length === 0;
const hasSurgicalProcedures: ICondition<MedicalHistoryAndPriorTreatmentDto> = ({ formState: { value } = {} }) => !!value?.surgicalProcedures && value?.surgicalProcedures?.length > 0;

const MedicalHistoryAndPriorTreatment: FunctionComponent<IMedicalHistoryAndPriorTreatmentProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        title: {
            padding: theme.spacing(2, 2, 2),
            color: theme.palette.secondary.main
        },
        titleContainer: {
            padding: theme.spacing(2, 2, 2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }, 
        subtitle: {
            padding: theme.spacing(2, 2, 0)
        },
        patientInformation: {
            padding: theme.spacing(2, 2, 0)
        },
        alert: {
            padding: theme.spacing(2)
        },
        protocolDeviations: {
            padding: theme.spacing(2)
        },
        formPadding: {
            padding: theme.spacing(2)
        },
        buttonGroup: {
            padding: theme.spacing(2),
            textAlign: 'right'
        }
    }), [theme])

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>

                <GroupedFields>
                    <Typography variant="h2" sx={styles.title}>
                        PREVIOUS BREAST CANCER TREATMENT
                    </Typography>

                    <GroupedField
                        name="ceaseDateAromataseInhibitor"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="ceaseDateCdkInhibitor"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <GroupedFields>
                    <GroupedField
                        name="chemotherapy"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="chemotherapyType"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>

                <GroupedFields>
                    <GroupedField
                        name="endocrineTherapy"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="endocrineTherapyType"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>

                <GroupedFields>
                    <GroupedField
                        name="secondLineEndocrineTherapy"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="secondLineEndocrineTherapyType"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>

                <GroupedFields>
                    <GroupedField
                        name="cdkInhibitor"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="cdkInhibitorType"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>

                <GroupedFields>
                    <GroupedField
                        name="bilateralOopherectomy"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>

                <GroupedFields>
                    <GroupedField
                        name="gnrhLhrhTherapy"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="gnrhLhrhTherapyType"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                

                <GroupedFields>
                    <Typography variant="h2" sx={styles.title}>
                        MEDICAL HISTORY
                    </Typography>

                    <GroupedField
                        name="relevantMedicalHistoryOrConMeds"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <Typography variant="h2" sx={styles.subtitle}>
                        Medical History/Concurrent Illness
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasMedicalHistory}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={MedicalHistory}
                            name="medicalHistory"
                            columns={medicalHistoryColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoMedicalHistory}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Medical History/Concurrent Illness"
                                description="There is no medical history/concurrent illness associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" sx={styles.subtitle}>
                        Concomitant Medications
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasConMeds}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={ConcomitantMedication}
                            name="concomitantMedications"
                            columns={conMedsColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoConMeds}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box sx={styles.alert}>
                            <BasicAlert
                                title="No Concomitant Medications"
                                description="There is no concomitant medication associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </Box>
                    </Condition>
                </GroupedFields>

                <GroupedFields>
                    <Typography variant="h2" sx={styles.subtitle}>
                        Surgical Procedures
                    </Typography>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasSurgicalProcedures}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedSubformGrid
                            type={SurgicalProcedure}
                            name="surgicalProcedures"
                            columns={surgicalProceduresColumns}
                            disabled
                        />
                    </Condition>
                    <Condition
                        type={MedicalHistoryAndPriorTreatmentDto}
                        condition={hasNoSurgicalProcedures}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <Box sx={styles.alert}>
                            <BasicAlert
                                title="No Surgical Procedures"
                                description="There is no surgical procedure associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </Box>
                    </Condition>
                </GroupedFields>


                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default MedicalHistoryAndPriorTreatment;
