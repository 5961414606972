import React, { FunctionComponent, useContext } from 'react';
import { FormsContext, FormsLayout, useAuthenticatedUser } from '@ngt/forms';
import { Navigate, Route, Routes, useRoutes, RouteObject, Outlet } from 'react-router-dom';
import { Patients, Patient, MedicalReview, MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute, MedicalReviewExtensionContext, IMedicalReviewFormMapping, MedicalReviewsByPatientStudyNumberRoute, MedicalReviewByPatientStudyNumberAndRepeatRoute, MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute, QuerySummary } from '@ngt/forms-medical-review';
import { TrialsExtensionContext, useInstitutions } from '@ngt/forms-trials';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';

import IFramePage from './containers/IFramePage';
import ScreeningBloodCollection from './containers/form/ScreeningBloodCollection';
import GeneralInformation from './containers/form/GeneralInformation';
import HistopathologyForm from './containers/form/HistopathologyForm';
import GeneralFunction from './containers/form/GeneralFunction';
import MedicalHistoryAndPriorTreatment from './containers/form/MedicalHistoryAndPriorTreatment';
import Death from './containers/form/Death';
import Progression from './containers/form/Progression';
import EndOfTreatmentAndFollowUp from './containers/form/EndOfTreatmentAndFollowUp';
import Treatment from './containers/form/Treatment';
import TumourAssessments from './containers/form/TumourAssessments';


const formMapping: Record<string, IMedicalReviewFormMapping> = {
    "general-information": {
        components: <GeneralInformation />
    },
    "screening-blood-collection": {
        components: <ScreeningBloodCollection />
    },
    "histopathology-form": {
        components: <HistopathologyForm />
    },
    "general-function": {
        components: <GeneralFunction />
    },
    "medical-history-and-prior-treatment": {
        components: <MedicalHistoryAndPriorTreatment />
    },
    "treatment": {
        components: <Treatment />
    },
    "end-of-treatment-and-follow-up-form": {
        components: <EndOfTreatmentAndFollowUp />
    },
    "tumour-assessments": {
        components: <TumourAssessments />
    },
    "progression": {
        components: <Progression />
    },
    "death": {
        components: <Death />
    }
}

const AppRoutes: FunctionComponent = () => {
    const { loading, error } = useInstitutions();

    if (!loading && error) {
        return (
            <ErrorPage errorType={parseInt(error.errorCode) ?? ErrorTypeEnum.e500} />
        )
    }

    return (
        <Routes>
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/:formDefinitionCode/:formRepeat/*`}
                element={
                    <MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute
                        resolveBeforeLoad
                        formMapping={formMapping}
                    >
                        <QuerySummary />
                    </MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute>
                }
            /> 
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/:formDefinitionCode/*`}
                element={
                    <MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute
                        resolveBeforeLoad
                        formMapping={formMapping}
                    >
                        <QuerySummary />
                    </MedicalReviewFormByPatientStudyNumberMedicalReviewRepeatAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/queries/*`}
                element={
                    <MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute
                        resolveBeforeLoad
                    >
                        <QuerySummary />
                    </MedicalReviewQueriesByPatientStudyNumberAndRepeatRoute>
                }
            />
            <Route
                path={`/medical-review/:patientStudyNumber/:medicalReviewRepeat/*`}
                element={
                    <MedicalReviewByPatientStudyNumberAndRepeatRoute
                        resolveBeforeLoad
                    >
                        <MedicalReview />
                    </MedicalReviewByPatientStudyNumberAndRepeatRoute>
                }
            />
            <Route 
                path={`/medical-review/:patientStudyNumber/*`}
                element={
                    <MedicalReviewsByPatientStudyNumberRoute
                        resolveBeforeLoad
                    >
                        <Patient />
                    </MedicalReviewsByPatientStudyNumberRoute>
                }
            />
            <Route
                path="/query-report/queries"
                element={
                    <IFramePage url="/queries" />
                }
            />
            <Route 
                path="/medical-review"
                element={<Patients />}
            />
            <Route path="*" element={<Navigate replace to="/medical-review" />} />
        </Routes>
    )
}

const App: FunctionComponent = () => {
    const formsContext = useContext(FormsContext);
    const trialsExtensionContext = useContext(TrialsExtensionContext);
    const medicalReviewExtensionContext = useContext(MedicalReviewExtensionContext);

    return (
        <FormsLayout>
            <AppRoutes />
        </FormsLayout>
    );
};

export default App;
