import { Box, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import {
    ContextFormTitle,
    ContextPatientInformation,
    ContextMedicalReviewFormBreadcrumbs, 
    ContextMedicalReviewForm,
    ContextFormQueryGrid,
    GroupedField,
    ICondition
} from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, DateDisplay, NumericDisplay, Condition } from '@ngt/forms';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import BiochemistryDisplay from './input/display/BiochemistryDisplay';
import FormDates from '../../components/form/FormDates';
import { GeneralFunction as GeneralFunctionDto } from '../../apis/dtos';
import { ConditionMode } from '@ngt/forms-core';

interface IGeneralFunctionProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2),
        color: theme.palette.secondary.main
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const postMenopausal: ICondition<GeneralFunctionDto> = ({ formState: { value } = {} }) => value?.menopausalAndChildBearingStatus?.toLowerCase().includes('post') ?? false;
const postMenopausalBelowSixty: ICondition<GeneralFunctionDto> = ({ formState: { value } = {} }) => value?.postmenopausalSpecification?.toLowerCase().includes('50') ?? false;
const periMenopause: ICondition<GeneralFunctionDto> = ({ formState: { value } = {} }) => !!value?.menopausalAndChildBearingStatus?.toLowerCase().includes('peri') ?? false;

const GeneralFunction: FunctionComponent<IGeneralFunctionProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        title: {
            padding: theme.spacing(2, 2, 2),
            color: theme.palette.secondary.main
        },
        titleContainer: {
            padding: theme.spacing(2, 2, 2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }, 
        subtitle: {
            padding: theme.spacing(1, 2, 0)
        },
        patientInformation: {
            padding: theme.spacing(2, 2, 0)
        },
        alert: {
            padding: theme.spacing(2)
        },
        protocolDeviations: {
            padding: theme.spacing(2)
        },
        formPadding: {
            padding: theme.spacing(2)
        },
        buttonGroup: {
            padding: theme.spacing(2),
            textAlign: 'right'
        },
    }), [theme])

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    
                    <FormDates />
                </Box>
                <Typography sx={{ padding: theme.spacing(0, 2, 2), fontSize: '0.95rem', color: 'red' }}>
                    <i>* = If any of the values does not meet the eligibility criteria, then please review and check if any protocol deviation was reported or eligibility waiver was granted to the patient.</i>
                </Typography>
                <GroupedFields>
                    <Typography variant="h2" sx={styles.title}>
                        PATIENT FUNCTION
                    </Typography>
                    <Typography sx={{ padding: theme.spacing(0, 2, 2), fontSize: '0.95rem' }}>
                        <i>Note: results for screening laboratory tests are included in the on-treatment laboratory tables.</i>
                    </Typography>
                    <Typography variant="h4" sx={styles.subtitle}>
                        Baseline ECOG
                    </Typography>
                    <GroupedField
                        name="ecog"
                        component={BiochemistryDisplay}
                        GroupStyleProps={groupStyleProps}
                        decimalPlaces={0}
                        notDoneName=""
                        llnName=""
                        ulnName="ecogUln"
                        eligibilityUlnName="ecogEligibilityUln"
                        unitName=""
                    />
                    <Typography variant="h4" sx={styles.subtitle}>
                        Menopausal and Child-Bearing Status 
                    </Typography>
                    <GroupedField
                        name="menopausalAndChildBearingStatus"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <Condition
                        type={GeneralFunctionDto}
                        condition={postMenopausal}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="postmenopausalSpecification"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={GeneralFunctionDto}
                            condition={postMenopausalBelowSixty}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="oestradiol"
                                component={BiochemistryDisplay}
                                GroupStyleProps={groupStyleProps}
                                decimalPlaces={1}
                                notDoneName=""
                                llnName=""
                                ulnName=""
                                unitName=""
                            />
                            <GroupedField
                                name="lh"
                                component={BiochemistryDisplay}
                                GroupStyleProps={groupStyleProps}
                                decimalPlaces={1}
                                notDoneName=""
                                llnName=""
                                ulnName=""
                                unitName=""
                            />
                            <GroupedField
                                name="fsh"
                                component={BiochemistryDisplay}
                                GroupStyleProps={groupStyleProps}
                                decimalPlaces={1}
                                notDoneName=""
                                llnName=""
                                ulnName=""
                                unitName=""
                            />
                        </Condition>
                    </Condition>
                    <Condition
                        type={GeneralFunctionDto}
                        condition={periMenopause}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="baselinePregnancyTest"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="patientConsentedToTreatment"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </Condition>
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" sx={styles.title}>
                        CARDIAC FUNCTION
                    </Typography>
                    <Typography variant="h4" sx={styles.subtitle}>
                        Baseline ECG
                    </Typography>
                    <GroupedField
                        name="ecg"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="ecgDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="qtcf"
                        component={BiochemistryDisplay}
                        GroupStyleProps={groupStyleProps}
                        decimalPlaces={0}
                        notDoneName=""
                        llnName=""
                        ulnName="qtcfUln"
                        eligibilityUlnName="qtcfEligibilityUln"
                        unitName=""
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> ms</Typography> 
                        }
                    />
                    <GroupedField
                        name="heartRate"
                        component={BiochemistryDisplay}
                        GroupStyleProps={groupStyleProps}
                        decimalPlaces={0}
                        notDoneName=""
                        llnName="heartRateLln"
                        eligibilityLlnName="heartRateEligibilityLln"
                        ulnName=""
                        unitName=""
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> bpm</Typography>
                        }
                    />
                    <Typography variant="h4" sx={styles.subtitle}>
                        Baseline LVEF
                    </Typography>
                    <GroupedField
                        name="echo"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="lvefDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="lvef"
                        component={BiochemistryDisplay}
                        GroupStyleProps={groupStyleProps}
                        decimalPlaces={0}
                        notDoneName=""
                        llnName="lvefLln"
                        eligibilityLlnName="lvefEligibilityLln"
                        unitName=""
                        suffix={
                            <Typography color="textSecondary" variant="caption" component="span"> %</Typography>
                        }
                    />
                    
                </GroupedFields>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default GeneralFunction;
