/* Options:
Date: 2024-02-19 11:12:46
Version: 6.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44375

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IDelete
{
}

export interface IUserGetSingle
{
    metadata: { [index: string]: Object; };
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet
{
    id?: number;
}

export interface IConfigurationGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet
{
    code: string;
}

export interface IDataGetSingleById extends IGet
{
    id?: number;
}

export interface IDataGetCollection extends IGet
{
    skip?: number;
    take?: number;
}

export enum MedicalReviewStatus
{
    New = 1,
    InProgress = 2,
    Complete = 3,
    Cancelled = 4,
}

export class AlpelisibTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventName: string;
    public administered: string;
    public actualDose?: number;
    public doseInterrupted: string;
    public doseInterruptedReason: string;
    public doseInterruptedSpec: string;
    public doseReduced: string;
    public doseReducedReason: string;
    public doseReducedSpec: string;
    public startDate?: string;
    public stopDate?: string;
    public lastAdministration: string;
    public lastAdministrationReason: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AlpelisibTreatment>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByMedicalReviewIdAndRepeat extends IGet
{
    medicalReviewId?: number;
    repeat?: number;
}

export interface IFormPostUpdateById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export class Annotation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Annotation>) { (Object as any).assign(this, init); }
}

export class CapecitabineTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventName: string;
    public administered: string;
    public startDate?: string;
    public stopDate?: string;
    public dose?: number;
    public doseReduced: string;
    public treatmentDelayed: string;
    public treatmentDelayedReason: string;
    public lastAdministration: string;
    public lastAdministrationReason: string;
    public lastAdministrationSpec: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CapecitabineTreatment>) { (Object as any).assign(this, init); }
}

export class Comment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public date?: string;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public crfName: string;
    public commentText: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Comment>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedication implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public genericName: string;
    public indication: string;
    public startDate: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedication>) { (Object as any).assign(this, init); }
}

export class FulvestrantTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventName: string;
    public administered: string;
    public administeredReason: string;
    public administeredSpec: string;
    public date?: string;
    public lastAdministration: string;
    public lastAdministrationReason: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FulvestrantTreatment>) { (Object as any).assign(this, init); }
}

export class GoserelinTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventName: string;
    public menopausalStatus: string;
    public administered: string;
    public administeredReason: string;
    public administeredSpec: string;
    public date?: string;
    public lastAdministration: string;
    public lastAdministrationReason: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GoserelinTreatment>) { (Object as any).assign(this, init); }
}

export class MedicalHistory implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public medicalCondition: string;
    public medicalConditionSpecify: string;
    public medicalConditionGrade?: number;
    public currentAtRegistration: string;
    public dateOfDiagnosis: string;
    public treatmentOngoing: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistory>) { (Object as any).assign(this, init); }
}

export class NewLesion implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public lesionNumber: string;
    public site: string;
    public siteSpec: string;
    public organLocation: string;
    public date?: string;
    public methodOfMeasurement: string;
    public methodOfMeasurementSpec: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NewLesion>) { (Object as any).assign(this, init); }
}

export class NonMeasurableLesion implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public timepoint: string;
    public notDoneReason: string;
    public notDoneReasonSpecify: string;
    public reasonForImaging: string;
    public reasonForImagingSpec: string;
    public imagingType: string;
    public lesion1Status: string;
    public lesion2Status: string;
    public lesion3Status: string;
    public lesion4Status: string;
    public lesion5Status: string;
    public nonTargetLesionResponse: string;
    public nonTargetLesionResponseSpecify: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NonMeasurableLesion>) { (Object as any).assign(this, init); }
}

export class OverallResponse implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public eventName: string;
    public response: string;
    public responseSpecify: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OverallResponse>) { (Object as any).assign(this, init); }
}

export class ProtocolDeviation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public discrepancyNoteId?: number;
    public studyEventId?: number;
    public eventName: string;
    public eventRepeat?: number;
    public eventCrfId?: number;
    public crfName: string;
    public entityName: string;
    public entityValue: string;
    public description: string;
    public detailedNote: string;
    public groupName: string;
    public groupRepeat?: number;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProtocolDeviation>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public section: string;
    public type: string;
    public name: string;
    public path: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class SurgicalProcedure implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public procedure: string;
    public laterality: string;
    public dateOfProcedure?: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SurgicalProcedure>) { (Object as any).assign(this, init); }
}

export class TargetMeasurableLesion implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public studyEventId?: number;
    public eventCrfId?: number;
    public timepoint: string;
    public notDoneReason: string;
    public notDoneReasonSpecify: string;
    public reasonForImaging: string;
    public reasonForImagingSpec: string;
    public imagingType: string;
    public lesion1Measurement?: number;
    public lesion2Measurement?: number;
    public lesion3Measurement?: number;
    public lesion4Measurement?: number;
    public lesion5Measurement?: number;
    public sumOfAllTargetLesions?: number;
    public targetLesionResponse: string;
    public targetLesionResponseSpecify: string;
    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TargetMeasurableLesion>) { (Object as any).assign(this, init); }
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export class Query implements IQuery
{
    public id?: number;
    public medicalReviewId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public parentQueryId?: number;
    // @Ignore()
    public queryNumber?: number;

    public repeat?: number;
    public question: string;
    public response: string;
    public recipientId?: number;
    public status?: QueryStatus;
    public issuedNotificationDate?: string;
    public respondedNotificationDate?: string;
    public cancelledNotificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Query>) { (Object as any).assign(this, init); }
}

export class Death implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public dateOfDeath?: string;
    public causeOfDeath: string;
    public causeOfDeathSpec: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Death>) { (Object as any).assign(this, init); }
}

export class EndOfTreatmentAndFollowUp implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public dateOfEot?: string;
    public physicalEvaluationCompleted: string;
    public physicalEvaluationCompletedReason: string;
    public reasonForDiscontinuation: string;
    public reasonForDiscontinuationSpec: string;
    public weight?: number;
    public ecog: string;
    public bodyTemperature?: number;
    public systolicBloodPressure: string;
    public diastolicBloodPressure: string;
    public heartRate: string;
    public hasFollowUp?: boolean;
    public followUpNotApplicable?: boolean;
    public dateOfConsultation?: string;
    public followUpBy: string;
    public followUpBySpec: string;
    public anyChangesSincePreviousAssessment: string;
    public participantCommencedNewAntiCancerTherapy: string;
    public dateNewTreatmentCommenced?: string;
    public specifyTreatment: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<EndOfTreatmentAndFollowUp>) { (Object as any).assign(this, init); }
}

export class GeneralFunction implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public ecog?: number;
    public ecogUln?: number;
    public ecogEligibilityUln?: number;
    public menopausalAndChildBearingStatus: string;
    public postmenopausalSpecification: string;
    public oestradiol?: number;
    public lh?: number;
    public fsh?: number;
    public baselinePregnancyTest: string;
    public patientConsentedToTreatment: string;
    public ecg: string;
    public ecgDate?: string;
    public qtcf?: number;
    public qtcfUln?: number;
    public qtcfEligibilityUln?: number;
    public heartRate?: number;
    public heartRateLln?: number;
    public heartRateEligibilityLln?: number;
    public echo: string;
    public lvefDate?: string;
    public lvef?: number;
    public lvefLln?: number;
    public lvefEligibilityLln?: number;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GeneralFunction>) { (Object as any).assign(this, init); }
}

export class GeneralInformation implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public waivered?: boolean;
    public waiverDate?: string;
    public waiverReason: string;
    public withdrawalDate?: string;
    public withdrawalDecision: string;
    // @Ignore()
    public protocolDeviations: ProtocolDeviation[];

    // @Ignore()
    public comments: Comment[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<GeneralInformation>) { (Object as any).assign(this, init); }
}

export class HistopathologyForm implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public patientPresentWithDeNovo: string;
    public dateOfHistopathologicDiagnosis?: string;
    public orSample: string;
    public orDateOfSampleCollection?: string;
    public orPercentageOfCellsStained: string;
    public prSample: string;
    public prDateOfSampleCollection?: string;
    public prPercentageOfCellsStained: string;
    public her2Sample: string;
    public her2DateOfSampleCollection?: string;
    public her2IhcScore: string;
    public her2IshScore: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<HistopathologyForm>) { (Object as any).assign(this, init); }
}

export class MedicalHistoryAndPriorTreatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public ceaseDateAromataseInhibitor?: string;
    public ceaseDateCdkInhibitor?: string;
    public chemotherapy: string;
    public chemotherapyType: string;
    public endocrineTherapy: string;
    public endocrineTherapyType: string;
    public secondLineEndocrineTherapy: string;
    public secondLineEndocrineTherapyType: string;
    public cdkInhibitor: string;
    public cdkInhibitorType: string;
    public bilateralOopherectomy: string;
    public gnrhLhrhTherapy: string;
    public gnrhLhrhTherapyType: string;
    public relevantMedicalHistoryOrConMeds: string;
    // @Ignore()
    public medicalHistory: MedicalHistory[];

    // @Ignore()
    public concomitantMedications: ConcomitantMedication[];

    // @Ignore()
    public surgicalProcedures: SurgicalProcedure[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatment>) { (Object as any).assign(this, init); }
}

export class Progression implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eventCrfId?: number;
    public firstDocumentationOfProgression?: string;
    public methodOfConfirmation: string;
    public methodOfConfirmationSpecify: string;
    public firstConfirmationOfProgression?: string;
    public participantExperiencedIsolatedCnsProgression: string;
    public participantExperiencedIsolatedCnsProgressionSpec: string;
    public newTreatmentForCnsProgressionInitiated: string;
    public newTreatmentForCnsProgressionInitiatedSpec: string;
    public dateNewTreatmentCommenced?: string;
    public participantCommencedNewAntiCancerTherapy: string;
    public dateNewAntiCancerTherapyCommenced?: string;
    public specifyTreatment: string;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Progression>) { (Object as any).assign(this, init); }
}

export class ScreeningBloodCollection implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public bloodSample1DateOfCollection?: string;
    public bloodSample1DateOfSampleReceived?: string;
    public bloodSample1AssayResult: string;
    public bloodSample1E542k: string;
    public bloodSample1E545k: string;
    public bloodSample1H1047l: string;
    public bloodSample1H1047r: string;
    public bloodSample1ReceivingAntiCancerTherapy: string;
    public bloodSample2DateOfCollection?: string;
    public bloodSample2DateOfSampleReceived?: string;
    public bloodSample2AssayResult: string;
    public bloodSample2E542k: string;
    public bloodSample2E545k: string;
    public bloodSample2H1047l: string;
    public bloodSample2H1047r: string;
    public bloodSample2ReceivingAntiCancerTherapy: string;
    public hasSecondSample?: boolean;
    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ScreeningBloodCollection>) { (Object as any).assign(this, init); }
}

export class Treatment implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public treatmentArm: string;
    public dosesGoserelinTp: string;
    public dosesFulvestrantTp: string;
    public dosesAlpelisibTp: string;
    public dosesCapecitabineTp: string;
    public plannedStartDate?: string;
    public goserelinApplicable?: boolean;
    // @Ignore()
    public goserelinTreatments: GoserelinTreatment[];

    // @Ignore()
    public fulvestrantTreatments: FulvestrantTreatment[];

    // @Ignore()
    public alpelisibTreatments: AlpelisibTreatment[];

    // @Ignore()
    public capecitabineTreatments: CapecitabineTreatment[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class TumourAssessments implements IMedicalReviewForm
{
    public id?: number;
    public medicalReviewId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public targetMeasurableLesion1Site: string;
    public targetMeasurableLesion1SiteSpec: string;
    public targetMeasurableLesion1OrganLocation: string;
    public targetMeasurableLesion2Site: string;
    public targetMeasurableLesion2SiteSpec: string;
    public targetMeasurableLesion2OrganLocation: string;
    public targetMeasurableLesion3Site: string;
    public targetMeasurableLesion3SiteSpec: string;
    public targetMeasurableLesion3OrganLocation: string;
    public targetMeasurableLesion4Site: string;
    public targetMeasurableLesion4SiteSpec: string;
    public targetMeasurableLesion4OrganLocation: string;
    public targetMeasurableLesion5Site: string;
    public targetMeasurableLesion5SiteSpec: string;
    public targetMeasurableLesion5OrganLocation: string;
    public nonMeasurableLesion1Site: string;
    public nonMeasurableLesion1SiteSpec: string;
    public nonMeasurableLesion1OrganLocation: string;
    public nonMeasurableLesion2Site: string;
    public nonMeasurableLesion2SiteSpec: string;
    public nonMeasurableLesion2OrganLocation: string;
    public nonMeasurableLesion3Site: string;
    public nonMeasurableLesion3SiteSpec: string;
    public nonMeasurableLesion3OrganLocation: string;
    public nonMeasurableLesion4Site: string;
    public nonMeasurableLesion4SiteSpec: string;
    public nonMeasurableLesion4OrganLocation: string;
    public nonMeasurableLesion5Site: string;
    public nonMeasurableLesion5SiteSpec: string;
    public nonMeasurableLesion5OrganLocation: string;
    // @Ignore()
    public targetMeasurableLesions: TargetMeasurableLesion[];

    // @Ignore()
    public nonMeasurableLesions: NonMeasurableLesion[];

    // @Ignore()
    public newLesions: NewLesion[];

    // @Ignore()
    public overallResponses: OverallResponse[];

    // @Ignore()
    public annotations: Annotation[];

    // @Ignore()
    public sourceDocuments: SourceDocument[];

    // @Ignore()
    public queries: Query[];

    public medicalReviewStatus?: MedicalReviewStatus;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TumourAssessments>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CoordinatingGroup implements ICoordinatingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CoordinatingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class MedicalReviewPatient implements IMedicalReviewPatient
{
    public id?: number;
    public institutionId?: number;
    public studyNumber: string;
    public treatmentArm: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public medicalReviewers: number[];

    // @Ignore()
    public requiresAttention: number[];

    // @Ignore()
    public reviewStatus?: MedicalReviewStatus;

    public constructor(init?: Partial<MedicalReviewPatient>) { (Object as any).assign(this, init); }
}

export interface IPatientGetSingleByStudyNumber
{
    studyNumber: string;
}

export class MedicalReviewRequestContext
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public countryId?: number;
    public institutionId?: number;
    public patientId?: number;
    public reviewerIds: number[];
    public medicalReviewId?: number;
    public reviewerId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public fileId?: number;
    public queryId?: number;

    public constructor(init?: Partial<MedicalReviewRequestContext>) { (Object as any).assign(this, init); }
}

export enum MedicalReviewPermission
{
    Access = 1,
    ViewMasterGroup = 2,
    ViewCoordinatingGroup = 3,
    ViewCountry = 4,
    ViewInstitution = 5,
    ViewPatient = 6,
    ViewMedicalReview = 7,
    ViewMedicalReviewForm = 8,
    ViewQuery = 9,
    CreateMedicalReview = 10,
    CompleteMedicalReview = 11,
    CancelMedicalReview = 12,
    CompleteMedicalReviewForm = 13,
    IssueQuery = 14,
    RespondToQuery = 15,
    CloseQuery = 16,
    ViewAllPatients = 17,
    CancelQuery = 18,
    UpdateMedicalReview = 19,
}

export class Reviewer implements IReviewer
{
    public id?: number;
    public onTrial?: boolean;
    public masterGroups: { [index: number]: boolean; };
    public coordinatingGroups: { [index: number]: boolean; };
    public institutions: { [index: number]: boolean; };
    public usernames: string[];
    public name: string;
    public email: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Reviewer>) { (Object as any).assign(this, init); }
}

export class MedicalReview implements IMedicalReview
{
    public id?: number;
    public patientId?: number;
    public medicalReviewDefinitionId?: number;
    public status?: MedicalReviewStatus;
    public reviewerId?: number;
    public repeat?: number;
    public startDate?: string;
    public updateDate?: string;
    public completeDate?: string;
    public cancelDate?: string;
    public formDefinitionIds: number[];
    public notificationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalReview>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewGetSingleByPatientIdAndRepeat extends IGet
{
    patientId?: number;
    repeat?: number;
}

export interface IMedicalReviewGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export class MedicalReviewCreationOptions
{
    public formDefinitionIds: number[];
    public includeUnchangedForms?: boolean;
    public compareFormsAcrossMedicalReviewDefinitions?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationOptions>) { (Object as any).assign(this, init); }
}

export class MedicalReviewUpdateOptions extends MedicalReviewCreationOptions
{
    public addNewForms?: boolean;
    public deleteOldForms?: boolean;

    public constructor(init?: Partial<MedicalReviewUpdateOptions>) { super(init); (Object as any).assign(this, init); }
}

export interface IMedicalReviewPostUpdateReview extends IPost
{
    metadata: { [index: string]: Object; };
    medicalReviewId?: number;
    options: MedicalReviewUpdateOptions;
}

export interface IMedicalReviewPostFormValidationCollectionById extends IPost
{
    id?: number;
    metadata: { [index: string]: Object; };
}

export interface IQueryGetCollectionByMedicalReviewId extends IGet
{
    medicalReviewId?: number;
}

export class UpdatedFormProperty implements IUpdatedFormProperty
{
    public id?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyPath: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UpdatedFormProperty>) { (Object as any).assign(this, init); }
}

export interface IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId extends IGet
{
    formDefinitionId?: number;
    formId?: number;
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class User implements IUserDto
{
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class LookupItem
{
    public id?: number;
    public value: string;
    public order?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormType implements IFormType
{
    public id?: number;
    public code: string;
    public name: string;

    public constructor(init?: Partial<FormType>) { (Object as any).assign(this, init); }
}

export enum ValidationErrorType
{
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: number;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class ValidationError implements IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ValidationError>) { (Object as any).assign(this, init); }
}

export class ValidationResult
{
    public result: ValidationResultType;
    public errors: ValidationError[];
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ValidationResult>) { (Object as any).assign(this, init); }
}

export class MedicalReviewDefinition implements IMedicalReviewDefinition
{
    public id?: number;
    public code: string;
    public name: string;
    public availableFormDefinitionIds: number[];
    public defaultFormDefinitionIds: number[];

    public constructor(init?: Partial<MedicalReviewDefinition>) { (Object as any).assign(this, init); }
}

export class QueryRecipient implements IQueryRecipient
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<QueryRecipient>) { (Object as any).assign(this, init); }
}

export interface IMedicalReviewForm extends IForm
{
    medicalReviewId?: number;
    medicalReviewStatus?: MedicalReviewStatus;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IMasterGroup extends IDataModel, IHasCode
{
    name: string;
}

export interface IHasCode
{
    code: string;
}

export interface ICoordinatingGroup extends IDataModel, IHasMasterGroupId, IHasCode
{
    name: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel, IHasCode
{
    name: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCoordinatingGroupId, IHasCountryId, IHasCode
{
    name: string;
    enabled?: boolean;
    activatedDate?: string;
}

export interface IHasCoordinatingGroupId
{
    coordinatingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IMedicalReviewPatient extends IPatient
{
    treatmentArm: string;
    medicalReviewers: number[];
    requiresAttention: number[];
    reviewStatus?: MedicalReviewStatus;
}

export interface IPatient extends IDataModel, IHasInstitutionId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IReviewer extends IDataModel
{
    name: string;
    usernames: string[];
    email: string;
    onTrial?: boolean;
    masterGroups: { [index: number]: boolean; };
    coordinatingGroups: { [index: number]: boolean; };
    institutions: { [index: number]: boolean; };
}

export interface IMedicalReview extends IDataModel, IHasPatientId
{
    medicalReviewDefinitionId?: number;
    status?: MedicalReviewStatus;
    reviewerId?: number;
    repeat?: number;
    formDefinitionIds: number[];
    startDate?: string;
    updateDate?: string;
    completeDate?: string;
    cancelDate?: string;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IQuery extends IDataModel, IHasFormDefinitionId, IHasFormId
{
    medicalReviewId?: number;
    propertyName: string;
    parentQueryId?: number;
    queryNumber?: number;
    repeat?: number;
    question: string;
    response: string;
    recipientId?: number;
    status?: QueryStatus;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IUpdatedFormProperty extends IDataModel
{
    formDefinitionId?: number;
    formId?: number;
    propertyPath: string;
}

export interface IUserDto
{
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem
{
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode
{
    name: string;
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode
{
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IValidationError
{
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IMedicalReviewDefinition extends IConfigurationModel, IHasCode
{
    name: string;
    availableFormDefinitionIds: number[];
    defaultFormDefinitionIds: number[];
}

export interface IQueryRecipient extends IConfigurationModel
{
    name: string;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class QueueEmailJobsResponse
{
    public responseStatus: ResponseStatus;
    public count?: number;

    public constructor(init?: Partial<QueueEmailJobsResponse>) { (Object as any).assign(this, init); }
}

export class SendMedicalReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;
    public emailNotRequired: boolean;

    public constructor(init?: Partial<SendMedicalReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsByIdsAsyncResponse
{
    public responseStatus: ResponseStatus;
    public patients: MedicalReviewPatient[];

    public constructor(init?: Partial<GetPatientsByIdsAsyncResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public user: User;

    public constructor(init?: Partial<UserSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: FormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: FormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AlpelisibTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AlpelisibTreatment;

    public constructor(init?: Partial<AlpelisibTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AlpelisibTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: AlpelisibTreatment[];

    public constructor(init?: Partial<AlpelisibTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AlpelisibTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AlpelisibTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Annotation[];

    public constructor(init?: Partial<AnnotationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AnnotationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<AnnotationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CapecitabineTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CapecitabineTreatment;

    public constructor(init?: Partial<CapecitabineTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CapecitabineTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CapecitabineTreatment[];

    public constructor(init?: Partial<CapecitabineTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CapecitabineTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CapecitabineTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Comment[];

    public constructor(init?: Partial<CommentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CommentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ConcomitantMedication[];

    public constructor(init?: Partial<ConcomitantMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FulvestrantTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: FulvestrantTreatment;

    public constructor(init?: Partial<FulvestrantTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FulvestrantTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: FulvestrantTreatment[];

    public constructor(init?: Partial<FulvestrantTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FulvestrantTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<FulvestrantTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GoserelinTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GoserelinTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GoserelinTreatment[];

    public constructor(init?: Partial<GoserelinTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GoserelinTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<GoserelinTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistorySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalHistory[];

    public constructor(init?: Partial<MedicalHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NewLesion[];

    public constructor(init?: Partial<NewLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NewLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NewLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonMeasurableLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonMeasurableLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: NonMeasurableLesion[];

    public constructor(init?: Partial<NonMeasurableLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonMeasurableLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<NonMeasurableLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OverallResponseSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponseSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OverallResponseCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: OverallResponse[];

    public constructor(init?: Partial<OverallResponseCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OverallResponseValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<OverallResponseValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ProtocolDeviation[];

    public constructor(init?: Partial<ProtocolDeviationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProtocolDeviationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ProtocolDeviationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgicalProcedureSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SurgicalProcedure;

    public constructor(init?: Partial<SurgicalProcedureSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgicalProcedureCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: SurgicalProcedure[];

    public constructor(init?: Partial<SurgicalProcedureCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgicalProcedureValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurgicalProcedureValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetMeasurableLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetMeasurableLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion[];

    public constructor(init?: Partial<TargetMeasurableLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetMeasurableLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TargetMeasurableLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Death[];

    public constructor(init?: Partial<DeathCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeathValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<DeathValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndOfTreatmentAndFollowUpSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: EndOfTreatmentAndFollowUp;

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndOfTreatmentAndFollowUpCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: EndOfTreatmentAndFollowUp[];

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EndOfTreatmentAndFollowUpValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralFunctionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GeneralFunction;

    public constructor(init?: Partial<GeneralFunctionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralFunctionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GeneralFunction[];

    public constructor(init?: Partial<GeneralFunctionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralFunctionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<GeneralFunctionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: GeneralInformation[];

    public constructor(init?: Partial<GeneralInformationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralInformationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<GeneralInformationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HistopathologyFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: HistopathologyForm;

    public constructor(init?: Partial<HistopathologyFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HistopathologyFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: HistopathologyForm[];

    public constructor(init?: Partial<HistopathologyFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HistopathologyFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<HistopathologyFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndPriorTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndPriorTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment[];

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndPriorTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProgressionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Progression;

    public constructor(init?: Partial<ProgressionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProgressionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Progression[];

    public constructor(init?: Partial<ProgressionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProgressionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ProgressionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningBloodCollectionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ScreeningBloodCollection;

    public constructor(init?: Partial<ScreeningBloodCollectionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningBloodCollectionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: ScreeningBloodCollection[];

    public constructor(init?: Partial<ScreeningBloodCollectionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningBloodCollectionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ScreeningBloodCollectionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourAssessmentsSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TumourAssessments;

    public constructor(init?: Partial<TumourAssessmentsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourAssessmentsCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: TumourAssessments[];

    public constructor(init?: Partial<TumourAssessmentsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourAssessmentsValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TumourAssessmentsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MasterGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: CoordinatingGroup[];

    public constructor(init?: Partial<CoordinatingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoordinatingGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CountryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InstitutionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReviewPatient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

export class PostHasPermissionsResponse
{
    public responseStatus: ResponseStatus;
    public permissions: { [index: string]: boolean; };

    public constructor(init?: Partial<PostHasPermissionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition;

    public constructor(init?: Partial<MedicalReviewDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: MedicalReviewDefinition[];

    public constructor(init?: Partial<MedicalReviewDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: QueryRecipient;

    public constructor(init?: Partial<QueryRecipientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryRecipientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public configuration: QueryRecipient[];

    public constructor(init?: Partial<QueryRecipientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Reviewer[];

    public constructor(init?: Partial<ReviewerCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReviewerValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ReviewerValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview[];

    public constructor(init?: Partial<MedicalReviewCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MedicalReviewValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewCreationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: MedicalReview;

    // @DataMember(Order=3)
    public hasNoForms?: boolean;

    // @DataMember(Order=4)
    public hasNoChanges?: boolean;

    public constructor(init?: Partial<MedicalReviewCreationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalReviewValidationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult[];

    public constructor(init?: Partial<MedicalReviewValidationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QuerySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QuerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<QueryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public data: UpdatedFormProperty[];

    public constructor(init?: Partial<UpdatedFormPropertyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpdatedFormPropertyValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UpdatedFormPropertyValidationResponse>) { (Object as any).assign(this, init); }
}

export class QueueAvailableMedicalReviewEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueAvailableMedicalReviewEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueAvailableMedicalReviewEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class QueueStartedAndCompletedMedicalReviewEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueStartedAndCompletedMedicalReviewEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueStartedAndCompletedMedicalReviewEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

export class SendMedicalReviewsAvailableEmails implements IReturn<SendMedicalReviewEmailResponse>
{
    public reviewerId?: number;

    public constructor(init?: Partial<SendMedicalReviewsAvailableEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMedicalReviewsAvailableEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendStartedAndCompletedMedicalReviewsEmail implements IReturn<SendMedicalReviewEmailResponse>
{

    public constructor(init?: Partial<SendStartedAndCompletedMedicalReviewsEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStartedAndCompletedMedicalReviewsEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class SendMedicalReviewCompleteEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public medicalReviewId?: number;

    public constructor(init?: Partial<SendMedicalReviewCompleteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMedicalReviewCompleteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

// @Route("/medical-review/patients-by-ids", "GET")
export class GetPatientsByIdsAsync implements IReturn<GetPatientsByIdsAsyncResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetPatientsByIdsAsync>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientsByIdsAsync'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientsByIdsAsyncResponse(); }
}

export class QueueInstitutionQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueInstitutionQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueInstitutionQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/emails/institution-query-summary/{InstitutionId}")
export class SendInstitutionQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<SendInstitutionQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendInstitutionQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

// @Route("/emails/reviewer-query-summary/{ReviewerId}")
export class SendReviewerQuerySummaryEmail implements IReturn<SendMedicalReviewEmailResponse>
{
    public reviewerId?: number;

    public constructor(init?: Partial<SendReviewerQuerySummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendReviewerQuerySummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedicalReviewEmailResponse(); }
}

export class QueueReviewerQuerySummaryEmails implements IReturn<QueueEmailJobsResponse>
{

    public constructor(init?: Partial<QueueReviewerQuerySummaryEmails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueReviewerQuerySummaryEmails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueEmailJobsResponse(); }
}

// @Route("/queries", "GET")
export class QueryReport
{
    public institutionId?: number;
    public patientId?: number;
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/medical-review/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/medical-review/user/single/user", "GET")
// @Api(Description="User")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=500)
// @DataContract
export class UserGetSingle implements IReturn<UserSingleResponse>, IUserGetSingle
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<UserGetSingle>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UserGetSingle'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserSingleResponse(); }
}

// @Route("/medical-review/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/medical-review/form-names", "GET")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

// @Route("/medical-review/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

// @Route("/medical-review/form-type/collection", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeCollectionResponse(); }
}

// @Route("/medical-review/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormTypeSingleResponse(); }
}

// @Route("/medical-review/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/medical-review/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

// @Route("/medical-review/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/medical-review/form/alpelisib-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - AlpelisibTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AlpelisibTreatmentGetSingleById implements IReturn<AlpelisibTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AlpelisibTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AlpelisibTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AlpelisibTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/alpelisib-treatment/collection", "GET")
// @Api(Description="Form - AlpelisibTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class AlpelisibTreatmentGetCollection implements IReturn<AlpelisibTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AlpelisibTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AlpelisibTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AlpelisibTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/alpelisib-treatment/create", "POST")
// @Api(Description="Form - AlpelisibTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AlpelisibTreatmentPostCreate implements IReturn<AlpelisibTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AlpelisibTreatment;

    public constructor(init?: Partial<AlpelisibTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AlpelisibTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AlpelisibTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/alpelisib-treatment/save", "POST")
// @Api(Description="Form - AlpelisibTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AlpelisibTreatmentPostSave implements IReturn<AlpelisibTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AlpelisibTreatment;

    public constructor(init?: Partial<AlpelisibTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AlpelisibTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AlpelisibTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/alpelisib-treatment/validate", "POST")
// @Api(Description="Form - AlpelisibTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class AlpelisibTreatmentPostValidate implements IReturn<AlpelisibTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: AlpelisibTreatment;

    public constructor(init?: Partial<AlpelisibTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AlpelisibTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AlpelisibTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/alpelisib-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - AlpelisibTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AlpelisibTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<AlpelisibTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AlpelisibTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AlpelisibTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AlpelisibTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/alpelisib-treatment/single/update", "POST")
// @Api(Description="Form - AlpelisibTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AlpelisibTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class AlpelisibTreatmentPostUpdateById implements IReturn<AlpelisibTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AlpelisibTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AlpelisibTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AlpelisibTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/annotation/single/id/{Id}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleById implements IReturn<AnnotationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AnnotationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/collection", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationCollectionResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetCollection implements IReturn<AnnotationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<AnnotationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationCollectionResponse(); }
}

// @Route("/medical-review/form/annotation/create", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostCreate implements IReturn<AnnotationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/save", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostSave implements IReturn<AnnotationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/validate", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationValidationResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostValidate implements IReturn<AnnotationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Annotation;

    public constructor(init?: Partial<AnnotationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationValidationResponse(); }
}

// @Route("/medical-review/form/annotation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationGetSingleByMedicalReviewIdAndRepeat implements IReturn<AnnotationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<AnnotationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/annotation/single/update", "POST")
// @Api(Description="Form - Annotation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.AnnotationSingleResponse)", StatusCode=500)
// @DataContract
export class AnnotationPostUpdateById implements IReturn<AnnotationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<AnnotationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnnotationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnnotationSingleResponse(); }
}

// @Route("/medical-review/form/capecitabine-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - CapecitabineTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class CapecitabineTreatmentGetSingleById implements IReturn<CapecitabineTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CapecitabineTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CapecitabineTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CapecitabineTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/capecitabine-treatment/collection", "GET")
// @Api(Description="Form - CapecitabineTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class CapecitabineTreatmentGetCollection implements IReturn<CapecitabineTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CapecitabineTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CapecitabineTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CapecitabineTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/capecitabine-treatment/create", "POST")
// @Api(Description="Form - CapecitabineTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class CapecitabineTreatmentPostCreate implements IReturn<CapecitabineTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CapecitabineTreatment;

    public constructor(init?: Partial<CapecitabineTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CapecitabineTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CapecitabineTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/capecitabine-treatment/save", "POST")
// @Api(Description="Form - CapecitabineTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class CapecitabineTreatmentPostSave implements IReturn<CapecitabineTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CapecitabineTreatment;

    public constructor(init?: Partial<CapecitabineTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CapecitabineTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CapecitabineTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/capecitabine-treatment/validate", "POST")
// @Api(Description="Form - CapecitabineTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class CapecitabineTreatmentPostValidate implements IReturn<CapecitabineTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CapecitabineTreatment;

    public constructor(init?: Partial<CapecitabineTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CapecitabineTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CapecitabineTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/capecitabine-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - CapecitabineTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class CapecitabineTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<CapecitabineTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CapecitabineTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CapecitabineTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CapecitabineTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/capecitabine-treatment/single/update", "POST")
// @Api(Description="Form - CapecitabineTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CapecitabineTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class CapecitabineTreatmentPostUpdateById implements IReturn<CapecitabineTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CapecitabineTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CapecitabineTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CapecitabineTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/comment/single/id/{Id}", "GET")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentGetSingleById implements IReturn<CommentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CommentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/collection", "GET")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentCollectionResponse)", StatusCode=500)
// @DataContract
export class CommentGetCollection implements IReturn<CommentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CommentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentCollectionResponse(); }
}

// @Route("/medical-review/form/comment/create", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentPostCreate implements IReturn<CommentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/save", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentPostSave implements IReturn<CommentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/validate", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentValidationResponse)", StatusCode=500)
// @DataContract
export class CommentPostValidate implements IReturn<CommentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Comment;

    public constructor(init?: Partial<CommentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommentValidationResponse(); }
}

// @Route("/medical-review/form/comment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentGetSingleByMedicalReviewIdAndRepeat implements IReturn<CommentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<CommentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/comment/single/update", "POST")
// @Api(Description="Form - Comment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.CommentSingleResponse)", StatusCode=500)
// @DataContract
export class CommentPostUpdateById implements IReturn<CommentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<CommentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommentSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/single/id/{Id}", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleById implements IReturn<ConcomitantMedicationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/collection", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollection implements IReturn<ConcomitantMedicationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/create", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostCreate implements IReturn<ConcomitantMedicationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/save", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSave implements IReturn<ConcomitantMedicationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/validate", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidate implements IReturn<ConcomitantMedicationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByMedicalReviewIdAndRepeat implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/concomitant-medication/single/update", "POST")
// @Api(Description="Form - ConcomitantMedication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostUpdateById implements IReturn<ConcomitantMedicationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ConcomitantMedicationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/medical-review/form/fulvestrant-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - FulvestrantTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class FulvestrantTreatmentGetSingleById implements IReturn<FulvestrantTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FulvestrantTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FulvestrantTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FulvestrantTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/fulvestrant-treatment/collection", "GET")
// @Api(Description="Form - FulvestrantTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class FulvestrantTreatmentGetCollection implements IReturn<FulvestrantTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FulvestrantTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FulvestrantTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FulvestrantTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/fulvestrant-treatment/create", "POST")
// @Api(Description="Form - FulvestrantTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class FulvestrantTreatmentPostCreate implements IReturn<FulvestrantTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: FulvestrantTreatment;

    public constructor(init?: Partial<FulvestrantTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FulvestrantTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FulvestrantTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/fulvestrant-treatment/save", "POST")
// @Api(Description="Form - FulvestrantTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class FulvestrantTreatmentPostSave implements IReturn<FulvestrantTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: FulvestrantTreatment;

    public constructor(init?: Partial<FulvestrantTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FulvestrantTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FulvestrantTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/fulvestrant-treatment/validate", "POST")
// @Api(Description="Form - FulvestrantTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class FulvestrantTreatmentPostValidate implements IReturn<FulvestrantTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: FulvestrantTreatment;

    public constructor(init?: Partial<FulvestrantTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FulvestrantTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FulvestrantTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/fulvestrant-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - FulvestrantTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class FulvestrantTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<FulvestrantTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<FulvestrantTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FulvestrantTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FulvestrantTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/fulvestrant-treatment/single/update", "POST")
// @Api(Description="Form - FulvestrantTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.FulvestrantTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class FulvestrantTreatmentPostUpdateById implements IReturn<FulvestrantTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<FulvestrantTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FulvestrantTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FulvestrantTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentGetSingleById implements IReturn<GoserelinTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GoserelinTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/collection", "GET")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentGetCollection implements IReturn<GoserelinTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<GoserelinTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/create", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostCreate implements IReturn<GoserelinTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/save", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostSave implements IReturn<GoserelinTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/validate", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostValidate implements IReturn<GoserelinTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GoserelinTreatment;

    public constructor(init?: Partial<GoserelinTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoserelinTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<GoserelinTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<GoserelinTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/goserelin-treatment/single/update", "POST")
// @Api(Description="Form - GoserelinTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GoserelinTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class GoserelinTreatmentPostUpdateById implements IReturn<GoserelinTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<GoserelinTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GoserelinTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoserelinTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleById implements IReturn<MedicalHistorySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/medical-history/collection", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollection implements IReturn<MedicalHistoryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

// @Route("/medical-review/form/medical-history/create", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostCreate implements IReturn<MedicalHistorySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/medical-history/save", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSave implements IReturn<MedicalHistorySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/medical-history/validate", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidate implements IReturn<MedicalHistoryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

// @Route("/medical-review/form/medical-history/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByMedicalReviewIdAndRepeat implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/medical-history/single/update", "POST")
// @Api(Description="Form - MedicalHistory")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostUpdateById implements IReturn<MedicalHistorySingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionGetSingleById implements IReturn<NewLesionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NewLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/collection", "GET")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class NewLesionGetCollection implements IReturn<NewLesionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NewLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionCollectionResponse(); }
}

// @Route("/medical-review/form/new-lesion/create", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostCreate implements IReturn<NewLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/save", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostSave implements IReturn<NewLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/validate", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionValidationResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostValidate implements IReturn<NewLesionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NewLesion;

    public constructor(init?: Partial<NewLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NewLesionValidationResponse(); }
}

// @Route("/medical-review/form/new-lesion/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionGetSingleByMedicalReviewIdAndRepeat implements IReturn<NewLesionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NewLesionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/new-lesion/single/update", "POST")
// @Api(Description="Form - NewLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NewLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NewLesionPostUpdateById implements IReturn<NewLesionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NewLesionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewLesionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NewLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionGetSingleById implements IReturn<NonMeasurableLesionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NonMeasurableLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/collection", "GET")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionGetCollection implements IReturn<NonMeasurableLesionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<NonMeasurableLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionCollectionResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/create", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostCreate implements IReturn<NonMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/save", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostSave implements IReturn<NonMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/validate", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionValidationResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostValidate implements IReturn<NonMeasurableLesionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: NonMeasurableLesion;

    public constructor(init?: Partial<NonMeasurableLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonMeasurableLesionValidationResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionGetSingleByMedicalReviewIdAndRepeat implements IReturn<NonMeasurableLesionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<NonMeasurableLesionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/non-measurable-lesion/single/update", "POST")
// @Api(Description="Form - NonMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.NonMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonMeasurableLesionPostUpdateById implements IReturn<NonMeasurableLesionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<NonMeasurableLesionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonMeasurableLesionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/single/id/{Id}", "GET")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponseGetSingleById implements IReturn<OverallResponseSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OverallResponseGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponseGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/collection", "GET")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseCollectionResponse)", StatusCode=500)
// @DataContract
export class OverallResponseGetCollection implements IReturn<OverallResponseCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<OverallResponseGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponseGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseCollectionResponse(); }
}

// @Route("/medical-review/form/overall-response/create", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostCreate implements IReturn<OverallResponseSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponsePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/save", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostSave implements IReturn<OverallResponseSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponsePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/validate", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseValidationResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostValidate implements IReturn<OverallResponseValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: OverallResponse;

    public constructor(init?: Partial<OverallResponsePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OverallResponseValidationResponse(); }
}

// @Route("/medical-review/form/overall-response/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponseGetSingleByMedicalReviewIdAndRepeat implements IReturn<OverallResponseSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<OverallResponseGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponseGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/overall-response/single/update", "POST")
// @Api(Description="Form - OverallResponse")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.OverallResponseSingleResponse)", StatusCode=500)
// @DataContract
export class OverallResponsePostUpdateById implements IReturn<OverallResponseSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<OverallResponsePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OverallResponsePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OverallResponseSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/single/id/{Id}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleById implements IReturn<ProtocolDeviationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/collection", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationCollectionResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetCollection implements IReturn<ProtocolDeviationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ProtocolDeviationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationCollectionResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/create", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostCreate implements IReturn<ProtocolDeviationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/save", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostSave implements IReturn<ProtocolDeviationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/validate", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationValidationResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostValidate implements IReturn<ProtocolDeviationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ProtocolDeviation;

    public constructor(init?: Partial<ProtocolDeviationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationValidationResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat implements IReturn<ProtocolDeviationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/protocol-deviation/single/update", "POST")
// @Api(Description="Form - ProtocolDeviation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProtocolDeviationSingleResponse)", StatusCode=500)
// @DataContract
export class ProtocolDeviationPostUpdateById implements IReturn<ProtocolDeviationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ProtocolDeviationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProtocolDeviationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProtocolDeviationSingleResponse(); }
}

// @Route("/medical-review/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/collection", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

// @Route("/medical-review/form/source-document/create", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostCreate implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/save", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/validate", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

// @Route("/medical-review/form/source-document/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByMedicalReviewIdAndRepeat implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/source-document/single/update", "POST")
// @Api(Description="Form - SourceDocument")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostUpdateById implements IReturn<SourceDocumentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SourceDocumentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/medical-review/form/surgical-procedure/single/id/{Id}", "GET")
// @Api(Description="Form - SurgicalProcedure")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalProcedureGetSingleById implements IReturn<SurgicalProcedureSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurgicalProcedureGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgicalProcedureGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgicalProcedureSingleResponse(); }
}

// @Route("/medical-review/form/surgical-procedure/collection", "GET")
// @Api(Description="Form - SurgicalProcedure")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalProcedureCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalProcedureCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalProcedureCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalProcedureCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgicalProcedureGetCollection implements IReturn<SurgicalProcedureCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurgicalProcedureGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgicalProcedureGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgicalProcedureCollectionResponse(); }
}

// @Route("/medical-review/form/surgical-procedure/create", "POST")
// @Api(Description="Form - SurgicalProcedure")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalProcedurePostCreate implements IReturn<SurgicalProcedureSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurgicalProcedure;

    public constructor(init?: Partial<SurgicalProcedurePostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgicalProcedurePostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgicalProcedureSingleResponse(); }
}

// @Route("/medical-review/form/surgical-procedure/save", "POST")
// @Api(Description="Form - SurgicalProcedure")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalProcedurePostSave implements IReturn<SurgicalProcedureSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurgicalProcedure;

    public constructor(init?: Partial<SurgicalProcedurePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgicalProcedurePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurgicalProcedureSingleResponse(); }
}

// @Route("/medical-review/form/surgical-procedure/validate", "POST")
// @Api(Description="Form - SurgicalProcedure")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalProcedureValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalProcedureValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalProcedureValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalProcedureValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalProcedureValidationResponse)", StatusCode=500)
// @DataContract
export class SurgicalProcedurePostValidate implements IReturn<SurgicalProcedureValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: SurgicalProcedure;

    public constructor(init?: Partial<SurgicalProcedurePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgicalProcedurePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurgicalProcedureValidationResponse(); }
}

// @Route("/medical-review/form/surgical-procedure/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - SurgicalProcedure")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalProcedureGetSingleByMedicalReviewIdAndRepeat implements IReturn<SurgicalProcedureSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurgicalProcedureGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgicalProcedureGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SurgicalProcedureSingleResponse(); }
}

// @Route("/medical-review/form/surgical-procedure/single/update", "POST")
// @Api(Description="Form - SurgicalProcedure")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgicalProcedureSingleResponse)", StatusCode=500)
// @DataContract
export class SurgicalProcedurePostUpdateById implements IReturn<SurgicalProcedureSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<SurgicalProcedurePostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SurgicalProcedurePostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SurgicalProcedureSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionGetSingleById implements IReturn<TargetMeasurableLesionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TargetMeasurableLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/collection", "GET")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionGetCollection implements IReturn<TargetMeasurableLesionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TargetMeasurableLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionCollectionResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/create", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostCreate implements IReturn<TargetMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/save", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostSave implements IReturn<TargetMeasurableLesionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/validate", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionValidationResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostValidate implements IReturn<TargetMeasurableLesionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TargetMeasurableLesion;

    public constructor(init?: Partial<TargetMeasurableLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetMeasurableLesionValidationResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionGetSingleByMedicalReviewIdAndRepeat implements IReturn<TargetMeasurableLesionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TargetMeasurableLesionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/target-measurable-lesion/single/update", "POST")
// @Api(Description="Form - TargetMeasurableLesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TargetMeasurableLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetMeasurableLesionPostUpdateById implements IReturn<TargetMeasurableLesionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TargetMeasurableLesionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetMeasurableLesionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetMeasurableLesionSingleResponse(); }
}

// @Route("/medical-review/form/death/single/id/{Id}", "GET")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathGetSingleById implements IReturn<DeathSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DeathGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/collection", "GET")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathCollectionResponse)", StatusCode=500)
// @DataContract
export class DeathGetCollection implements IReturn<DeathCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<DeathGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathCollectionResponse(); }
}

// @Route("/medical-review/form/death/create", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathPostCreate implements IReturn<DeathSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/save", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathPostSave implements IReturn<DeathSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/validate", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathValidationResponse)", StatusCode=500)
// @DataContract
export class DeathPostValidate implements IReturn<DeathValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Death;

    public constructor(init?: Partial<DeathPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathValidationResponse(); }
}

// @Route("/medical-review/form/death/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathGetSingleByMedicalReviewIdAndRepeat implements IReturn<DeathSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<DeathGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/death/single/update", "POST")
// @Api(Description="Form - Death")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.DeathSingleResponse)", StatusCode=500)
// @DataContract
export class DeathPostUpdateById implements IReturn<DeathSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<DeathPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeathPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeathSingleResponse(); }
}

// @Route("/medical-review/form/end-of-treatment-and-follow-up/single/id/{Id}", "GET")
// @Api(Description="Form - EndOfTreatmentAndFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class EndOfTreatmentAndFollowUpGetSingleById implements IReturn<EndOfTreatmentAndFollowUpSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndOfTreatmentAndFollowUpGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndOfTreatmentAndFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/end-of-treatment-and-follow-up/collection", "GET")
// @Api(Description="Form - EndOfTreatmentAndFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpCollectionResponse)", StatusCode=500)
// @DataContract
export class EndOfTreatmentAndFollowUpGetCollection implements IReturn<EndOfTreatmentAndFollowUpCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndOfTreatmentAndFollowUpGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndOfTreatmentAndFollowUpCollectionResponse(); }
}

// @Route("/medical-review/form/end-of-treatment-and-follow-up/create", "POST")
// @Api(Description="Form - EndOfTreatmentAndFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class EndOfTreatmentAndFollowUpPostCreate implements IReturn<EndOfTreatmentAndFollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndOfTreatmentAndFollowUp;

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndOfTreatmentAndFollowUpPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndOfTreatmentAndFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/end-of-treatment-and-follow-up/save", "POST")
// @Api(Description="Form - EndOfTreatmentAndFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class EndOfTreatmentAndFollowUpPostSave implements IReturn<EndOfTreatmentAndFollowUpSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndOfTreatmentAndFollowUp;

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndOfTreatmentAndFollowUpPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndOfTreatmentAndFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/end-of-treatment-and-follow-up/validate", "POST")
// @Api(Description="Form - EndOfTreatmentAndFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpValidationResponse)", StatusCode=500)
// @DataContract
export class EndOfTreatmentAndFollowUpPostValidate implements IReturn<EndOfTreatmentAndFollowUpValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: EndOfTreatmentAndFollowUp;

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndOfTreatmentAndFollowUpPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndOfTreatmentAndFollowUpValidationResponse(); }
}

// @Route("/medical-review/form/end-of-treatment-and-follow-up/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - EndOfTreatmentAndFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class EndOfTreatmentAndFollowUpGetSingleByMedicalReviewIdAndRepeat implements IReturn<EndOfTreatmentAndFollowUpSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndOfTreatmentAndFollowUpGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EndOfTreatmentAndFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/end-of-treatment-and-follow-up/single/update", "POST")
// @Api(Description="Form - EndOfTreatmentAndFollowUp")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.EndOfTreatmentAndFollowUpSingleResponse)", StatusCode=500)
// @DataContract
export class EndOfTreatmentAndFollowUpPostUpdateById implements IReturn<EndOfTreatmentAndFollowUpSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<EndOfTreatmentAndFollowUpPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EndOfTreatmentAndFollowUpPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EndOfTreatmentAndFollowUpSingleResponse(); }
}

// @Route("/medical-review/form/general-function/single/id/{Id}", "GET")
// @Api(Description="Form - GeneralFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralFunctionGetSingleById implements IReturn<GeneralFunctionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralFunctionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralFunctionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralFunctionSingleResponse(); }
}

// @Route("/medical-review/form/general-function/collection", "GET")
// @Api(Description="Form - GeneralFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralFunctionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralFunctionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralFunctionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralFunctionCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralFunctionGetCollection implements IReturn<GeneralFunctionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<GeneralFunctionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralFunctionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralFunctionCollectionResponse(); }
}

// @Route("/medical-review/form/general-function/create", "POST")
// @Api(Description="Form - GeneralFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralFunctionPostCreate implements IReturn<GeneralFunctionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralFunction;

    public constructor(init?: Partial<GeneralFunctionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralFunctionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralFunctionSingleResponse(); }
}

// @Route("/medical-review/form/general-function/save", "POST")
// @Api(Description="Form - GeneralFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralFunctionPostSave implements IReturn<GeneralFunctionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralFunction;

    public constructor(init?: Partial<GeneralFunctionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralFunctionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralFunctionSingleResponse(); }
}

// @Route("/medical-review/form/general-function/validate", "POST")
// @Api(Description="Form - GeneralFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralFunctionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralFunctionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralFunctionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralFunctionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralFunctionValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralFunctionPostValidate implements IReturn<GeneralFunctionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralFunction;

    public constructor(init?: Partial<GeneralFunctionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralFunctionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralFunctionValidationResponse(); }
}

// @Route("/medical-review/form/general-function/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - GeneralFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralFunctionGetSingleByMedicalReviewIdAndRepeat implements IReturn<GeneralFunctionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<GeneralFunctionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralFunctionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralFunctionSingleResponse(); }
}

// @Route("/medical-review/form/general-function/single/update", "POST")
// @Api(Description="Form - GeneralFunction")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralFunctionSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralFunctionPostUpdateById implements IReturn<GeneralFunctionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<GeneralFunctionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralFunctionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralFunctionSingleResponse(); }
}

// @Route("/medical-review/form/general-information/single/id/{Id}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleById implements IReturn<GeneralInformationSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/collection", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetCollection implements IReturn<GeneralInformationCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<GeneralInformationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationCollectionResponse(); }
}

// @Route("/medical-review/form/general-information/create", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostCreate implements IReturn<GeneralInformationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/save", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostSave implements IReturn<GeneralInformationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/validate", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostValidate implements IReturn<GeneralInformationValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: GeneralInformation;

    public constructor(init?: Partial<GeneralInformationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationValidationResponse(); }
}

// @Route("/medical-review/form/general-information/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationGetSingleByMedicalReviewIdAndRepeat implements IReturn<GeneralInformationSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<GeneralInformationGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/general-information/single/update", "POST")
// @Api(Description="Form - GeneralInformation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.GeneralInformationSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralInformationPostUpdateById implements IReturn<GeneralInformationSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<GeneralInformationPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralInformationPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralInformationSingleResponse(); }
}

// @Route("/medical-review/form/histopathology-form/single/id/{Id}", "GET")
// @Api(Description="Form - HistopathologyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyFormGetSingleById implements IReturn<HistopathologyFormSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<HistopathologyFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologyFormSingleResponse(); }
}

// @Route("/medical-review/form/histopathology-form/collection", "GET")
// @Api(Description="Form - HistopathologyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyFormCollectionResponse)", StatusCode=500)
// @DataContract
export class HistopathologyFormGetCollection implements IReturn<HistopathologyFormCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<HistopathologyFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologyFormCollectionResponse(); }
}

// @Route("/medical-review/form/histopathology-form/create", "POST")
// @Api(Description="Form - HistopathologyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyFormPostCreate implements IReturn<HistopathologyFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: HistopathologyForm;

    public constructor(init?: Partial<HistopathologyFormPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyFormPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologyFormSingleResponse(); }
}

// @Route("/medical-review/form/histopathology-form/save", "POST")
// @Api(Description="Form - HistopathologyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyFormPostSave implements IReturn<HistopathologyFormSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: HistopathologyForm;

    public constructor(init?: Partial<HistopathologyFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HistopathologyFormSingleResponse(); }
}

// @Route("/medical-review/form/histopathology-form/validate", "POST")
// @Api(Description="Form - HistopathologyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologyFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyFormValidationResponse)", StatusCode=500)
// @DataContract
export class HistopathologyFormPostValidate implements IReturn<HistopathologyFormValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: HistopathologyForm;

    public constructor(init?: Partial<HistopathologyFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HistopathologyFormValidationResponse(); }
}

// @Route("/medical-review/form/histopathology-form/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - HistopathologyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyFormGetSingleByMedicalReviewIdAndRepeat implements IReturn<HistopathologyFormSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<HistopathologyFormGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyFormGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HistopathologyFormSingleResponse(); }
}

// @Route("/medical-review/form/histopathology-form/single/update", "POST")
// @Api(Description="Form - HistopathologyForm")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.HistopathologyFormSingleResponse)", StatusCode=500)
// @DataContract
export class HistopathologyFormPostUpdateById implements IReturn<HistopathologyFormSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<HistopathologyFormPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HistopathologyFormPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HistopathologyFormSingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentGetSingleById implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/collection", "GET")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentGetCollection implements IReturn<MedicalHistoryAndPriorTreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/create", "POST")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentPostCreate implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/save", "POST")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentPostSave implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/validate", "POST")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentPostValidate implements IReturn<MedicalHistoryAndPriorTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalHistoryAndPriorTreatment;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentValidationResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/medical-history-and-prior-treatment/single/update", "POST")
// @Api(Description="Form - MedicalHistoryAndPriorTreatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MedicalHistoryAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndPriorTreatmentPostUpdateById implements IReturn<MedicalHistoryAndPriorTreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<MedicalHistoryAndPriorTreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndPriorTreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndPriorTreatmentSingleResponse(); }
}

// @Route("/medical-review/form/progression/single/id/{Id}", "GET")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionGetSingleById implements IReturn<ProgressionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProgressionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/progression/collection", "GET")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionCollectionResponse)", StatusCode=500)
// @DataContract
export class ProgressionGetCollection implements IReturn<ProgressionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ProgressionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProgressionCollectionResponse(); }
}

// @Route("/medical-review/form/progression/create", "POST")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionPostCreate implements IReturn<ProgressionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Progression;

    public constructor(init?: Partial<ProgressionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/progression/save", "POST")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionPostSave implements IReturn<ProgressionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Progression;

    public constructor(init?: Partial<ProgressionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/progression/validate", "POST")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionValidationResponse)", StatusCode=500)
// @DataContract
export class ProgressionPostValidate implements IReturn<ProgressionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Progression;

    public constructor(init?: Partial<ProgressionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProgressionValidationResponse(); }
}

// @Route("/medical-review/form/progression/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionGetSingleByMedicalReviewIdAndRepeat implements IReturn<ProgressionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ProgressionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/progression/single/update", "POST")
// @Api(Description="Form - Progression")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ProgressionSingleResponse)", StatusCode=500)
// @DataContract
export class ProgressionPostUpdateById implements IReturn<ProgressionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ProgressionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProgressionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProgressionSingleResponse(); }
}

// @Route("/medical-review/form/screening-blood-collection/single/id/{Id}", "GET")
// @Api(Description="Form - ScreeningBloodCollection")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningBloodCollectionGetSingleById implements IReturn<ScreeningBloodCollectionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningBloodCollectionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningBloodCollectionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningBloodCollectionSingleResponse(); }
}

// @Route("/medical-review/form/screening-blood-collection/collection", "GET")
// @Api(Description="Form - ScreeningBloodCollection")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningBloodCollectionGetCollection implements IReturn<ScreeningBloodCollectionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ScreeningBloodCollectionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningBloodCollectionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningBloodCollectionCollectionResponse(); }
}

// @Route("/medical-review/form/screening-blood-collection/create", "POST")
// @Api(Description="Form - ScreeningBloodCollection")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningBloodCollectionPostCreate implements IReturn<ScreeningBloodCollectionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ScreeningBloodCollection;

    public constructor(init?: Partial<ScreeningBloodCollectionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningBloodCollectionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningBloodCollectionSingleResponse(); }
}

// @Route("/medical-review/form/screening-blood-collection/save", "POST")
// @Api(Description="Form - ScreeningBloodCollection")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningBloodCollectionPostSave implements IReturn<ScreeningBloodCollectionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ScreeningBloodCollection;

    public constructor(init?: Partial<ScreeningBloodCollectionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningBloodCollectionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningBloodCollectionSingleResponse(); }
}

// @Route("/medical-review/form/screening-blood-collection/validate", "POST")
// @Api(Description="Form - ScreeningBloodCollection")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningBloodCollectionPostValidate implements IReturn<ScreeningBloodCollectionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: ScreeningBloodCollection;

    public constructor(init?: Partial<ScreeningBloodCollectionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningBloodCollectionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningBloodCollectionValidationResponse(); }
}

// @Route("/medical-review/form/screening-blood-collection/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - ScreeningBloodCollection")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningBloodCollectionGetSingleByMedicalReviewIdAndRepeat implements IReturn<ScreeningBloodCollectionSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<ScreeningBloodCollectionGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningBloodCollectionGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningBloodCollectionSingleResponse(); }
}

// @Route("/medical-review/form/screening-blood-collection/single/update", "POST")
// @Api(Description="Form - ScreeningBloodCollection")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ScreeningBloodCollectionSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningBloodCollectionPostUpdateById implements IReturn<ScreeningBloodCollectionSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ScreeningBloodCollectionPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningBloodCollectionPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningBloodCollectionSingleResponse(); }
}

// @Route("/medical-review/form/treatment/single/id/{Id}", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/treatment/collection", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentCollectionResponse(); }
}

// @Route("/medical-review/form/treatment/create", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostCreate implements IReturn<TreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/treatment/save", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostSave implements IReturn<TreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/treatment/validate", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostValidate implements IReturn<TreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentValidationResponse(); }
}

// @Route("/medical-review/form/treatment/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleByMedicalReviewIdAndRepeat implements IReturn<TreatmentSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TreatmentGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/treatment/single/update", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostUpdateById implements IReturn<TreatmentSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TreatmentPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/single/id/{Id}", "GET")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsGetSingleById implements IReturn<TumourAssessmentsSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TumourAssessmentsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/collection", "GET")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsGetCollection implements IReturn<TumourAssessmentsCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TumourAssessmentsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourAssessmentsCollectionResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/create", "POST")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsPostCreate implements IReturn<TumourAssessmentsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TumourAssessments;

    public constructor(init?: Partial<TumourAssessmentsPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/save", "POST")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsPostSave implements IReturn<TumourAssessmentsSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TumourAssessments;

    public constructor(init?: Partial<TumourAssessmentsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/validate", "POST")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsValidationResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsPostValidate implements IReturn<TumourAssessmentsValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: TumourAssessments;

    public constructor(init?: Partial<TumourAssessmentsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourAssessmentsValidationResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/single/medical-review-id-repeat/{MedicalReviewId}/{Repeat}", "GET")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsGetSingleByMedicalReviewIdAndRepeat implements IReturn<TumourAssessmentsSingleResponse>, IFormGetSingleByMedicalReviewIdAndRepeat
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TumourAssessmentsGetSingleByMedicalReviewIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsGetSingleByMedicalReviewIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/form/tumour-assessments/single/update", "POST")
// @Api(Description="Form - TumourAssessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TumourAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class TumourAssessmentsPostUpdateById implements IReturn<TumourAssessmentsSingleResponse>, IFormPostUpdateById
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<TumourAssessmentsPostUpdateById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourAssessmentsPostUpdateById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourAssessmentsSingleResponse(); }
}

// @Route("/medical-review/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/medical-review/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

// @Route("/medical-review/master-group/create", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostCreate implements IReturn<MasterGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/medical-review/master-group/save", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostSave implements IReturn<MasterGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/medical-review/master-group/validate", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostValidate implements IReturn<MasterGroupValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MasterGroupValidationResponse(); }
}

// @Route("/medical-review/coordinating-group/single/id/{Id}", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetSingleById implements IReturn<CoordinatingGroupSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoordinatingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/medical-review/coordinating-group/collection", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetCollection implements IReturn<CoordinatingGroupCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoordinatingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupCollectionResponse(); }
}

// @Route("/medical-review/coordinating-group/create", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostCreate implements IReturn<CoordinatingGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/medical-review/coordinating-group/save", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostSave implements IReturn<CoordinatingGroupSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
}

// @Route("/medical-review/coordinating-group/validate", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostValidate implements IReturn<CoordinatingGroupValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CoordinatingGroupPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CoordinatingGroupValidationResponse(); }
}

// @Route("/medical-review/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/medical-review/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/medical-review/country/create", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostCreate implements IReturn<CountrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/medical-review/country/save", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostSave implements IReturn<CountrySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/medical-review/country/validate", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=500)
// @DataContract
export class CountryPostValidate implements IReturn<CountryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CountryValidationResponse(); }
}

// @Route("/medical-review/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/medical-review/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/medical-review/institution/create", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostCreate implements IReturn<InstitutionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/medical-review/institution/save", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostSave implements IReturn<InstitutionSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/medical-review/institution/validate", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostValidate implements IReturn<InstitutionValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InstitutionValidationResponse(); }
}

// @Route("/medical-review/patient/single/id/{Id}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/patient/collection", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/medical-review/patient/create", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/patient/save", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/patient/validate", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReviewPatient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientValidationResponse(); }
}

// @Route("/medical-review/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/medical-review/open-clinica/document", "GET")
export class GetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    public fileName: string;

    public constructor(init?: Partial<GetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/medical-review/permissions", "POST")
export class PostHasPermissions implements IReturn<PostHasPermissionsResponse>, IPost
{
    public context: MedicalReviewRequestContext;
    public permissions: MedicalReviewPermission[];

    public constructor(init?: Partial<PostHasPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasPermissions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PostHasPermissionsResponse(); }
}

// @Route("/medical-review/medical-review-definition/single/id/{Id}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleById implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

// @Route("/medical-review/medical-review-definition/collection", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetCollection implements IReturn<MedicalReviewDefinitionCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionCollectionResponse(); }
}

// @Route("/medical-review/medical-review-definition/single/code/{Code}", "GET")
// @Api(Description="Medical Review Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReviewDefinition.MedicalReviewDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewDefinitionGetSingleByCode implements IReturn<MedicalReviewDefinitionSingleResponse>, IConfigurationGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MedicalReviewDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewDefinitionSingleResponse(); }
}

// @Route("/medical-review/query-recipient/single/id/{Id}", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientSingleResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetSingleById implements IReturn<QueryRecipientSingleResponse>, IConfigurationGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryRecipientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientSingleResponse(); }
}

// @Route("/medical-review/query-recipient/collection", "GET")
// @Api(Description="Query Recipient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.QueryRecipient.QueryRecipientCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryRecipientGetCollection implements IReturn<QueryRecipientCollectionResponse>, IConfigurationGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryRecipientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryRecipientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryRecipientCollectionResponse(); }
}

// @Route("/medical-review/reviewer/single/id/{Id}", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetSingleById implements IReturn<ReviewerSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ReviewerGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/collection", "GET")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerCollectionResponse)", StatusCode=500)
// @DataContract
export class ReviewerGetCollection implements IReturn<ReviewerCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ReviewerGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerCollectionResponse(); }
}

// @Route("/medical-review/reviewer/create", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostCreate implements IReturn<ReviewerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/save", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerSingleResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostSave implements IReturn<ReviewerSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerSingleResponse(); }
}

// @Route("/medical-review/reviewer/validate", "POST")
// @Api(Description="Reviewer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Reviewer.ReviewerValidationResponse)", StatusCode=500)
// @DataContract
export class ReviewerPostValidate implements IReturn<ReviewerValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Reviewer;

    public constructor(init?: Partial<ReviewerPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewerPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewerValidationResponse(); }
}

// @Route("/medical-review/medical-review/single/id/{Id}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleById implements IReturn<MedicalReviewSingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollection implements IReturn<MedicalReviewCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MedicalReviewGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

// @Route("/medical-review/medical-review/create", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreate implements IReturn<MedicalReviewSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/save", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostSave implements IReturn<MedicalReviewSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/validate", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostValidate implements IReturn<MedicalReviewValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    public constructor(init?: Partial<MedicalReviewPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationResponse(); }
}

// @Route("/medical-review/medical-review/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetSingleByPatientIdAndRepeat implements IReturn<MedicalReviewSingleResponse>, IMedicalReviewGetSingleByPatientIdAndRepeat
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MedicalReviewGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetSingleByPatientIdAndRepeat'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewGetCollectionByPatientId implements IReturn<MedicalReviewCollectionResponse>, IMedicalReviewGetCollectionByPatientId
{
    // @DataMember(Order=1)
    public patientId?: number;

    public constructor(init?: Partial<MedicalReviewGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalReviewCollectionResponse(); }
}

// @Route("/medical-review/medical-review/create-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostCreateReview implements IReturn<MedicalReviewCreationSingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: MedicalReview;

    // @DataMember(Order=2)
    public options: MedicalReviewCreationOptions;

    public constructor(init?: Partial<MedicalReviewPostCreateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostCreateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

// @Route("/medical-review/medical-review/update-review", "POST")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewCreationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostUpdateReview implements IReturn<MedicalReviewCreationSingleResponse>, IMedicalReviewPostUpdateReview
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public medicalReviewId?: number;

    // @DataMember(Order=2)
    public options: MedicalReviewUpdateOptions;

    public constructor(init?: Partial<MedicalReviewPostUpdateReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostUpdateReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewCreationSingleResponse(); }
}

// @Route("/medical-review/medical-review/collection/validate-forms", "GET")
// @Api(Description="Medical Review")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.MedicalReview.MedicalReviewValidationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalReviewPostFormValidationCollectionById implements IReturn<MedicalReviewValidationCollectionResponse>, IMedicalReviewPostFormValidationCollectionById
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalReviewPostFormValidationCollectionById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalReviewPostFormValidationCollectionById'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalReviewValidationCollectionResponse(); }
}

// @Route("/medical-review/query/single/id/{Id}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryGetSingleById implements IReturn<QuerySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<QueryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/collection", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollection implements IReturn<QueryCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<QueryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/query/create", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostCreate implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/save", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QuerySingleResponse)", StatusCode=500)
// @DataContract
export class QueryPostSave implements IReturn<QuerySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuerySingleResponse(); }
}

// @Route("/medical-review/query/validate", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryValidationResponse)", StatusCode=500)
// @DataContract
export class QueryPostValidate implements IReturn<QueryValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query;

    public constructor(init?: Partial<QueryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryValidationResponse(); }
}

// @Route("/medical-review/query/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryGetCollectionByMedicalReviewId implements IReturn<QueryCollectionResponse>, IQueryGetCollectionByMedicalReviewId
{
    // @DataMember(Order=1)
    public medicalReviewId?: number;

    public constructor(init?: Partial<QueryGetCollectionByMedicalReviewId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryGetCollectionByMedicalReviewId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/query/save/collection", "POST")
// @Api(Description="Query")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.Query.QueryCollectionResponse)", StatusCode=500)
// @DataContract
export class QueryPostSaveCollection implements IReturn<QueryCollectionResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: Query[];

    public constructor(init?: Partial<QueryPostSaveCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryPostSaveCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryCollectionResponse(); }
}

// @Route("/medical-review/updatedformproperty/single/id/{Id}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetSingleById implements IReturn<UpdatedFormPropertySingleResponse>, IDataGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/collection", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollection implements IReturn<UpdatedFormPropertyCollectionResponse>, IDataGetCollection
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

// @Route("/medical-review/updatedformproperty/create", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostCreate implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostCreate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/save", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertySingleResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostSave implements IReturn<UpdatedFormPropertySingleResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertySingleResponse(); }
}

// @Route("/medical-review/updatedformproperty/validate", "POST")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyValidationResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyPostValidate implements IReturn<UpdatedFormPropertyValidationResponse>
{
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    // @DataMember(Order=2)
    public data: UpdatedFormProperty;

    public constructor(init?: Partial<UpdatedFormPropertyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdatedFormPropertyValidationResponse(); }
}

// @Route("/medical-review/updatedformproperty/collection/form-definition-id-form-id/{FormDefinitionId}/{FormId}", "GET")
// @Api(Description="UpdatedFormProperty")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__MedicalReview.UpdatedFormProperty.UpdatedFormPropertyCollectionResponse)", StatusCode=500)
// @DataContract
export class UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId implements IReturn<UpdatedFormPropertyCollectionResponse>, IUpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    // @DataMember(Order=1)
    public formId?: number;

    public constructor(init?: Partial<UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatedFormPropertyGetCollectionByFormDefinitionIdAndFormId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UpdatedFormPropertyCollectionResponse(); }
}

