import { Box, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, DateDisplay } from '@ngt/forms';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import FormDates from '../../components/form/FormDates';
import Annotations from '../../components/form/Annotations';

interface IHistopathologyFormProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const HistopathologyForm: FunctionComponent<IHistopathologyFormProps> = () => {
    const {classes} = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <GroupedFields>
                    <GroupedField
                        name="patientPresentWithDeNovo"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="dateOfHistopathologicDiagnosis"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Receptor Status (Oestrogen Receptor) ER
                    </Typography>
                    <GroupedField
                        name="orSample"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="orDateOfSampleCollection"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="orPercentageOfCellsStained"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        Receptor Status (Progesterone Receptor) PR
                    </Typography>
                    <GroupedField
                        name="prSample"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="prDateOfSampleCollection"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="prPercentageOfCellsStained"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>
                <GroupedFields>
                    <Typography variant="h2" className={classes.subtitle}>
                        HER2 Expression
                    </Typography>
                    <GroupedField
                        name="her2Sample"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="her2DateOfSampleCollection"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="her2IhcScore"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="her2IshScore"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                </GroupedFields>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default HistopathologyForm;
