import { Box, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { Progression as ProgressionDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import IfElseDisplay from './input/display/IfElseDisplay';
import FormDates from '../../components/form/FormDates';
import ValueWithSpecificationDisplay from './input/display/ValueWithSpecificationDisplay';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';

interface IProgressionProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasNoProgression: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => !value?.eventCrfId;
const hasProgression: ICondition<ProgressionDto> = ({ formState: { value } = {} }) => !!value?.eventCrfId;

const conditionSubscription = { value: true };

const Progression: FunctionComponent<IProgressionProps> = () => {
    const {classes} = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <Condition
                    type={ProgressionDto}
                    condition={hasProgression}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedField
                        name="firstDocumentationOfProgression"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="methodOfConfirmation"
                        component={ValueWithSpecificationDisplay}
                        specificationName="methodOfConfirmationSpecify"
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="firstConfirmationOfProgression"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedField
                        name="participantExperiencedIsolatedCnsProgression"
                        component={ValueWithSpecificationDisplay}
                        specificationName="participantExperiencedIsolatedCnsProgressionSpec"
                        GroupStyleProps={groupStyleProps}
                    />
                    <GroupedFields>
                        <GroupedField
                            name="newTreatmentForCnsProgressionInitiated"
                            component={ValueWithSpecificationDisplay}
                            specificationName="newTreatmentForCnsProgressionInitiatedSpec"
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="dateNewTreatmentCommenced"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </GroupedFields>
                    <GroupedFields>
                        <GroupedField
                            name="participantCommencedNewAntiCancerTherapy"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="dateNewAntiCancerTherapyCommenced"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="specifyTreatment"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                    </GroupedFields>
                    
                    {
                        //<GroupedField
                        //    name="optionalTumourSampleCollected"
                        //    component={ValueWithReasonAndSpecificationDisplay}
                        //    reasonName="optionalTumourSampleCollectedReason"
                        //    specificationName="optionalTumourSampleCollectedSpecify"
                        //    GroupStyleProps={groupStyleProps}
                        ///>
                        //<GroupedField
                        //    name="siteOfBiopsy"
                        //    component={TextDisplay}
                        //    GroupStyleProps={groupStyleProps}
                        ///>
                        //<GroupedField
                        //    name="dateOfCollection"
                        //    component={DateDisplay}
                        //    GroupStyleProps={groupStyleProps}
                        ///>
                    }
                </Condition>
                <Condition
                    type={ProgressionDto}
                    condition={hasNoProgression}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No Progression Form"
                            description="There is no progression form associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default Progression;
