import { Box, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useMemo } from 'react';
import {
    ContextFormTitle,
    ContextPatientInformation,
    ContextMedicalReviewFormBreadcrumbs, 
    ContextMedicalReviewForm,
    ContextFormQueryGrid,
    GroupedField,
    ICondition
} from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, DateDisplay, NumericDisplay, Condition } from '@ngt/forms';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import BiochemistryDisplay from './input/display/BiochemistryDisplay';
import FormDates from '../../components/form/FormDates';
import { EndOfTreatmentAndFollowUp as EndOfTreatmentAndFollowUpDto } from '../../apis/dtos';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';
import ValueWithSpecificationDisplay from './input/display/ValueWithSpecificationDisplay';

interface IEndOfTreatmentAndFollowUpProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2),
        color: theme.palette.secondary.main
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const conditionSubscription = { value: true };

const hasEot: ICondition<EndOfTreatmentAndFollowUpDto> = ({ formState: { value } = {} }) => !!value?.eventCrfId;
const hasNoEot: ICondition<EndOfTreatmentAndFollowUpDto> = ({ formState: { value } = {} }) => !value?.eventCrfId;

const hasFollowUp: ICondition<EndOfTreatmentAndFollowUpDto> = ({ formState: { value } = {} }) => !!value?.hasFollowUp;
const hasNoFollowUp: ICondition<EndOfTreatmentAndFollowUpDto> = ({ formState: { value } = {} }) => !value?.hasFollowUp && !value?.followUpNotApplicable;
const followUpNotApplicable: ICondition<EndOfTreatmentAndFollowUpDto> = ({ formState: { value } = {} }) => !!value?.followUpNotApplicable;

const hasCommencedNewTherapy: ICondition<EndOfTreatmentAndFollowUpDto> = ({ formState: { value } = {} }) => value?.participantCommencedNewAntiCancerTherapy?.toLowerCase()?.includes("yes") ?? false;

const EndOfTreatmentAndFollowUp: FunctionComponent<IEndOfTreatmentAndFollowUpProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        title: {
            padding: theme.spacing(2, 2, 2),
            color: theme.palette.secondary.main
        },
        titleContainer: {
            padding: theme.spacing(2, 2, 2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }, 
        subtitle: {
            padding: theme.spacing(1, 2, 0)
        },
        patientInformation: {
            padding: theme.spacing(2, 2, 0)
        },
        alert: {
            padding: theme.spacing(2)
        },
        protocolDeviations: {
            padding: theme.spacing(2)
        },
        formPadding: {
            padding: theme.spacing(2)
        },
        buttonGroup: {
            padding: theme.spacing(2),
            textAlign: 'right'
        },
    }), [theme])

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <Condition
                    type={EndOfTreatmentAndFollowUpDto}
                    condition={hasEot}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedFields>
                        <Typography variant="h2" sx={styles.title}>
                            END OF TREATMENT
                        </Typography>

                        <GroupedField
                            name="dateOfEot"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="physicalEvaluationCompleted"
                            component={ValueWithReasonAndSpecificationDisplay}
                            reasonName="physicalEvaluationCompletedReason"
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="reasonForDiscontinuation"
                            component={ValueWithSpecificationDisplay}
                            specificationName="reasonForDiscontinuationSpec"
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="weight"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> kg</Typography>
                            }
                        />
                        <GroupedField
                            name="ecog"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="bodyTemperature"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> &deg;C</Typography>
                            }
                        />
                        <GroupedField
                            name="systolicBloodPressure"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> mmHg</Typography>
                            }
                        />
                        <GroupedField
                            name="diastolicBloodPressure"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> mmHg</Typography>
                            }
                        />
                        <GroupedField
                            name="heartRate"
                            component={NumericDisplay}
                            GroupStyleProps={groupStyleProps}
                            suffix={
                                <Typography color="textSecondary" variant="caption" component="span"> beats/minute</Typography>
                            }
                        />
                    </GroupedFields>
                </Condition>
                <Condition
                    type={EndOfTreatmentAndFollowUpDto}
                    condition={hasNoEot}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No End of Treatment Form"
                            description="There is no end of treatment form associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>

                <Condition
                    type={EndOfTreatmentAndFollowUpDto}
                    condition={hasFollowUp}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedFields>
                        <Typography variant="h2" sx={styles.title}>
                            FOLLOW UP
                        </Typography>
                        
                        <GroupedField
                            name="dateOfConsultation"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="followUpBy"
                            component={ValueWithSpecificationDisplay}
                            specificationName="followUpBySpec"
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="anyChangesSincePreviousAssessment"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="participantCommencedNewAntiCancerTherapy"
                            component={ValueWithSpecificationDisplay}
                            specificationName="specifyTreatment"
                            GroupStyleProps={groupStyleProps}
                        />
                        <Condition
                            type={EndOfTreatmentAndFollowUpDto}
                            condition={hasCommencedNewTherapy}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedField
                                name="dateNewTreatmentCommenced"
                                component={DateDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </Condition>
                    </GroupedFields>
                </Condition>
                <Condition
                    type={EndOfTreatmentAndFollowUpDto}
                    condition={hasNoFollowUp}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="No Follow Up Form"
                            description="There is no follow up form associated with this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>
                <Condition
                    type={EndOfTreatmentAndFollowUpDto}
                    condition={followUpNotApplicable}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <div className={classes.alert}>
                        <BasicAlert
                            title="Follow Up Not Applicable"
                            description="Follow up is not applicable to this patient."
                            icon={faInfoCircle}
                            severity="info"
                        />
                    </div>
                </Condition>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default EndOfTreatmentAndFollowUp;
