import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay, FormLabel, FieldProvider, NumericDisplay } from '@ngt/forms';
import { Treatment as TreatmentDto, FulvestrantTreatment, AlpelisibTreatment, CapecitabineTreatment, GoserelinTreatment } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import Annotations from '../../components/form/Annotations';
import SourceDocuments from '../../components/form/SourceDocuments';
import ValueWithSpecificationDisplay from './input/display/ValueWithSpecificationDisplay';
import ValueWithReasonAndSpecificationDisplay from './input/display/ValueWithReasonAndSpecificationDisplay';
import FormDates from '../../components/form/FormDates';

interface ITreatmentProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    centerText: {
        textAlign: 'center'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const isArmA: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => value?.treatmentArm?.includes('Arm A') ?? false;
const isArmB: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => value?.treatmentArm?.includes('Arm B') ?? false;

const goserelinApplicable: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => !!value?.goserelinApplicable;
const hasGoserelinTreatments: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.goserelinTreatments?.length ?? 0) > 0;
const hasNoGoserelinTreatments: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.goserelinTreatments?.length ?? 0) === 0;
const hasFulvestrantTreatments: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.fulvestrantTreatments?.length ?? 0) > 0;
const hasNoFulvestrantTreatments: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.fulvestrantTreatments?.length ?? 0) === 0;
const hasAlpelisibTreatments: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.alpelisibTreatments?.length ?? 0) > 0;
const hasNoAlpelisibTreatments: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.alpelisibTreatments?.length ?? 0) === 0;
const hasCapecitabineTreatments: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.capecitabineTreatments?.length ?? 0) > 0;
const hasNoCapecitabineTreatments: ICondition<TreatmentDto> = ({ formState: { value } = {} }) => (value?.capecitabineTreatments?.length ?? 0) === 0;


const conditionSubscription = { value: true };

const goserelinColumns: IGroupedSubformGridCell<GoserelinTreatment>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName
    },
    {
        name: 'menopausalStatus',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName
    },
    {
        name: 'administered',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="administeredReason"
                reasonPrependParentName
                reasonTableCell
                specificationName="administeredSpec"
                specificationPrependParentName
                specificationTableCell
            />
        ),
        sortValue: row => row?.administered
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.date
    },
    {
        name: 'lastAdministration',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    reasonName="lastAdministrationReason"
                    reasonPrependParentName
                    reasonTableCell
                    specificationName="lastAdministrationSpec"
                    specificationPrependParentName
                    specificationTableCell
                />
            </>
        ),
        sortValue: row => row?.lastAdministration
    }
];

const fulvestrantColumns: IGroupedSubformGridCell<FulvestrantTreatment>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName
    },
    {
        name: 'administered',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="administeredReason"
                reasonPrependParentName
                reasonTableCell
                specificationName="administeredSpec"
                specificationPrependParentName
                specificationTableCell
            />
        ),
        sortValue: row => row?.administered
    },
    {
        name: 'date',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.date
    },
    {
        name: 'lastAdministration',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    reasonName="lastAdministrationReason"
                    reasonPrependParentName
                    reasonTableCell
                />
            </>
        ),
        sortValue: row => row?.lastAdministration
    }
];

const alpelisibColumns: IGroupedSubformGridCell<AlpelisibTreatment>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName,
        width: 120,
    },
    {
        name: 'administered',
        content: (
            <Input
                component={ValueWithReasonAndSpecificationDisplay}
                reasonName="administeredReason"
                reasonPrependParentName
                reasonTableCell
                specificationName="administeredSpec"
                specificationPrependParentName
                specificationTableCell
            />
        ),
        width: 210,
        sortValue: row => row?.administered
    },
    {
        name: 'actualDose',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                }
            />
        ),
        sortValue: row => row?.actualDose
    },
    {
        name: 'doseInterrupted',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    reasonName="doseInterruptedReason"
                    reasonPrependParentName
                    reasonTableCell
                    specificationName="doseInterruptedSpec"
                    specificationPrependParentName
                    specificationTableCell
                />
            </>
        ),
        sortValue: row => row?.doseInterrupted
    },
    {
        name: 'doseReduced',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    reasonName="doseReducedReason"
                    reasonPrependParentName
                    reasonTableCell
                    specificationName="doseReducedSpec"
                    specificationPrependParentName
                    specificationTableCell
                />
            </>
        ),
        sortValue: row => row?.doseReduced
    },
    {
        name: 'startDate',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.startDate
    },
    {
        name: 'stopDate',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.stopDate
    },
    {
        name: 'lastAdministration',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    reasonName="lastAdministrationReason"
                    reasonPrependParentName
                    reasonTableCell
                />
            </>
        ),
        sortValue: row => row?.lastAdministration
    }
];

const capecitabineColumns: IGroupedSubformGridCell<CapecitabineTreatment>[] = [
    {
        name: 'eventName',
        content: (
            <Input component={TextDisplay} />
        ),
        sortValue: row => row?.eventName,
        width: 120
    },
    {
        name: 'administered',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        width: 210,
        sortValue: row => row?.administered
    },
    {
        name: 'startDate',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.startDate
    },
    {
        name: 'stopDate',
        content: (
            <Input component={DateDisplay} />
        ),
        sortValue: row => row?.stopDate
    },
    {
        name: 'dose',
        content: (
            <Input
                component={NumericDisplay}
                suffix={
                    <Typography color="textSecondary" variant="caption" component="span"> mg/m<sup>2</sup></Typography>
                }
            />
        ),
        sortValue: row => row?.dose
    },
    {
        name: 'doseReduced',
        content: (
            <>
                <Input
                    component={TextDisplay}
                />
            </>
        ),
        sortValue: row => row?.doseReduced
    },
    {
        name: 'treatmentDelayed',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    reasonName="treatmentDelayedReason"
                    reasonPrependParentName
                    reasonTableCell
                />
            </>
        ),
        sortValue: row => row?.treatmentDelayed
    },
    {
        name: 'lastAdministration',
        content: (
            <>
                <Input
                    component={ValueWithReasonAndSpecificationDisplay}
                    reasonName="lastAdministrationReason"
                    reasonPrependParentName
                    reasonTableCell
                    specificationName="lastAdministrationSpec"
                    specificationPrependParentName
                    specificationTableCell
                />
            </>
        ),
        sortValue: row => row?.lastAdministration
    }
];


const Treatment: FunctionComponent<ITreatmentProps> = () => {
    const {classes} = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Condition
                    type={TreatmentDto}
                    condition={isArmA}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <Box className={classes.titleContainer}>
                        <Typography variant="h1">
                            <ContextFormTitle />
                        </Typography>
                        <FormDates />
                    </Box>
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Treatment Exposure &ndash; ER +/ HER2 -
                        </Typography>
                        <GroupedField
                            name="treatmentArm"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="plannedStartDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <FieldProvider name="dosesGoserelinTp">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                    <TableCell>
                                        <FieldProvider name="dosesFulvestrantTp">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                    <TableCell>
                                        <FieldProvider name="dosesAlpelisibTp">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell><Typography>Dosage Level</Typography></TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesGoserelinTp"
                                            component={NumericDisplay}
                                            convert={(value) => 3.6}
                                            suffix={
                                                <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesFulvestrantTp"
                                            component={NumericDisplay}
                                            convert={(value) => 500}
                                            suffix={
                                                <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesAlpelisibTp"
                                            component={NumericDisplay}
                                            convert={(value) => 300}
                                            suffix={
                                                <Typography color="textSecondary" variant="caption" component="span"> mg</Typography>
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography>Treatment Phase</Typography></TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesGoserelinTp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesFulvestrantTp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesAlpelisibTp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </GroupedFields>
                </Condition>
                <Condition
                    type={TreatmentDto}
                    condition={isArmB}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Treatment Exposure &ndash; ER +/ HER2 -
                        </Typography>

                        <GroupedField
                            name="treatmentArm"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedField
                            name="plannedStartDate"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell width="40%"></TableCell>
                                    <TableCell>
                                        <FieldProvider name="dosesCapecitabineTp">
                                            <FormLabel />
                                        </FieldProvider>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell><Typography>Dosage level</Typography></TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesCapecitabineTp"
                                            component={TextDisplay}
                                            convert={(value) =>
                                                <>
                                                    1000 <Typography color="textSecondary" variant="caption" component="span"> mg/m<sup>2</sup></Typography> to 
                                                    1250 <Typography color="textSecondary" variant="caption" component="span"> mg/m<sup>2</sup></Typography>
                                                </>
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Typography>Treatment Phase</Typography></TableCell>
                                    <TableCell>
                                        <InputOnlyField
                                            name="dosesCapecitabineTp"
                                            component={TextDisplay}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </GroupedFields>
                </Condition>

                <Condition
                    type={TreatmentDto}
                    condition={isArmA}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <Condition
                        type={TreatmentDto}
                        condition={goserelinApplicable}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedFields>
                            <Typography variant="h2" className={classes.subtitle}>
                                Goserelin
                            </Typography>

                            <Condition
                                type={TreatmentDto}
                                condition={hasGoserelinTreatments}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <GroupedSubformGrid
                                    type={GoserelinTreatment}
                                    name="goserelinTreatments"
                                    columns={goserelinColumns}
                                    disabled
                                />
                            </Condition>
                            <Condition
                                type={TreatmentDto}
                                condition={hasNoGoserelinTreatments}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <div className={classes.alert}>
                                    <BasicAlert
                                        title="No Goserelin Treatments"
                                        description="There is no Goserelin treatment associated with this patient."
                                        icon={faInfoCircle}
                                        severity="info"
                                    />
                                </div>
                            </Condition>
                        </GroupedFields>
                    </Condition>
                    

                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Fulvestrant
                        </Typography>

                        <Condition
                            type={TreatmentDto}
                            condition={hasFulvestrantTreatments}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={FulvestrantTreatment}
                                name="fulvestrantTreatments"
                                columns={fulvestrantColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={TreatmentDto}
                            condition={hasNoFulvestrantTreatments}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Fulvestrant Treatments"
                                    description="There is no Fulvestrant treatment associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>

                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Alpelisib
                        </Typography>

                        <Condition
                            type={TreatmentDto}
                            condition={hasAlpelisibTreatments}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={AlpelisibTreatment}
                                name="alpelisibTreatments"
                                columns={alpelisibColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={TreatmentDto}
                            condition={hasNoAlpelisibTreatments}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Alpelisib Treatments"
                                    description="There is no Alpelisib treatment associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                </Condition>

                <Condition
                    type={TreatmentDto}
                    condition={isArmB}
                    subscription={conditionSubscription}
                    mode={ConditionMode.Show}
                >
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Capecitabine
                        </Typography>

                        <Condition
                            type={TreatmentDto}
                            condition={hasCapecitabineTreatments}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={CapecitabineTreatment}
                                name="capecitabineTreatments"
                                columns={capecitabineColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={TreatmentDto}
                            condition={hasNoCapecitabineTreatments}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Capecitabine Treatments"
                                    description="There is no Capecitabine treatment associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                </Condition>
               
                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default Treatment;
