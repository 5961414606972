import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, InputOnlyField, GroupedField, SymbolDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, TextDisplay, DateDisplay, FieldProvider, FormLabel } from '@ngt/forms';
import { MedicalReviewPatient } from '../../apis/dtos';
import { PatientContext } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import FormDates from '../../components/form/FormDates';
import IfElseDisplay from './input/display/IfElseDisplay';
import { DateTime } from 'luxon';

interface IScreeningBloodCollectionProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    }, 
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    greyRow: {
        '& td': {
            backgroundColor: theme.palette.grey[200]
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const dateAccessor = (value: string) => value ? DateTime.fromISO(value).toFormat('dd/MM/yyyy') : null;

const ScreeningBloodCollection: FunctionComponent<IScreeningBloodCollectionProps> = () => {
    const { classes } = useStyles();
    const theme = useTheme();

    const styles = useMemo(() => ({
        title: {
            padding: theme.spacing(2, 2, 2)
        },
        subtitle: {
            padding: theme.spacing(2, 2, 0)
        },
        patientInformation: {
            padding: theme.spacing(2, 2, 0)
        },
        alert: {
            padding: theme.spacing(2)
        },
        protocolDeviations: {
            padding: theme.spacing(2)
        },
        formPadding: {
            padding: theme.spacing(2)
        },
        buttonGroup: {
            padding: theme.spacing(2),
            textAlign: 'right'
        },
        greyRow: {
            '& td': {
                backgroundColor: theme.palette.grey[200]
            }
        },
        noBorderRow: {
            border: `${0} !important`,

            '& > *': {
                border: `${0} !important`
            }
        }
    }), [theme]);

    const { data } = useContext(PatientContext);

    const patient = data as MedicalReviewPatient;

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <Box sx={styles.patientInformation}>
                <ContextPatientInformation />
            </Box>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        width: '500px'
                                    }}
                                >
                                </TableCell>
                                <TableCell style={{ paddingRight: '10rem' }}>
                                    <Typography variant='h3' style={{ textAlign: 'left' }}>
                                        Blood Sample 1
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ paddingRight: '10rem' }}>
                                    <Typography variant='h3' style={{ textAlign: 'left' }}>
                                        Blood Sample 2
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ ...styles.noBorderRow  }}>
                                <TableCell>
                                    <FieldProvider name="bloodSample1DateOfCollection">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="bloodSample1DateOfCollection"
                                        component={DateDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="hasSecondSample"
                                        component={IfElseDisplay}
                                        ifName="hasSecondSample"
                                        ifAccessor={(value: any) => "Not Applicable"}
                                        ifTableCell
                                        condition={(value: any) => !value}
                                        elseName="bloodSample2DateOfCollection"
                                        elseAccessor={dateAccessor}
                                        elseTableCell
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="bloodSample1DateOfSampleReceived">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="bloodSample1DateOfSampleReceived"
                                        component={DateDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="hasSecondSample"
                                        component={IfElseDisplay}
                                        ifName="hasSecondSample"
                                        ifAccessor={(value: any) => "Not Applicable"}
                                        ifTableCell
                                        condition={(value: any) => !value}
                                        elseName="bloodSample2DateOfSampleReceived"
                                        elseAccessor={dateAccessor}
                                        elseTableCell
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="bloodSample1AssayResult">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="bloodSample1AssayResult"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="hasSecondSample"
                                        component={IfElseDisplay}
                                        ifName="hasSecondSample"
                                        ifAccessor={(value: any) => "Not Applicable"}
                                        ifTableCell
                                        condition={(value: any) => !value}
                                        elseName="bloodSample2AssayResult"
                                        elseTableCell
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="bloodSample1E542k">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="bloodSample1E542k"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="hasSecondSample"
                                        component={IfElseDisplay}
                                        ifName="hasSecondSample"
                                        ifAccessor={(value: any) => "Not Applicable"}
                                        ifTableCell
                                        condition={(value: any) => !value}
                                        elseName="bloodSample2E542k"
                                        elseTableCell
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="bloodSample1E545k">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="bloodSample1E545k"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="hasSecondSample"
                                        component={IfElseDisplay}
                                        ifName="hasSecondSample"
                                        ifAccessor={(value: any) => "Not Applicable"}
                                        ifTableCell
                                        condition={(value: any) => !value}
                                        elseName="bloodSample2E545k"
                                        elseTableCell
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="bloodSample1H1047l">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="bloodSample1H1047l"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="hasSecondSample"
                                        component={IfElseDisplay}
                                        ifName="hasSecondSample"
                                        ifAccessor={(value: any) => "Not Applicable"}
                                        ifTableCell
                                        condition={(value: any) => !value}
                                        elseName="bloodSample2H1047l"
                                        elseTableCell
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="bloodSample1H1047r">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="bloodSample1H1047r"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="hasSecondSample"
                                        component={IfElseDisplay}
                                        ifName="hasSecondSample"
                                        ifAccessor={(value: any) => "Not Applicable"}
                                        ifTableCell
                                        condition={(value: any) => !value}
                                        elseName="bloodSample2H1047r"
                                        elseTableCell
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ ...styles.noBorderRow }}>
                                <TableCell>
                                    <FieldProvider name="bloodSample1ReceivingAntiCancerTherapy">
                                        <FormLabel />
                                    </FieldProvider>
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="bloodSample1ReceivingAntiCancerTherapy"
                                        component={TextDisplay}
                                    />
                                </TableCell>
                                <TableCell>
                                    <InputOnlyField
                                        name="hasSecondSample"
                                        component={IfElseDisplay}
                                        ifName="hasSecondSample"
                                        ifAccessor={(value: any) => "Not Applicable"}
                                        ifTableCell
                                        condition={(value: any) => !value}
                                        elseName="bloodSample2ReceivingAntiCancerTherapy"
                                        elseTableCell
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>

                <Annotations />
                <SourceDocuments />
                <ContextFormQueryGrid name="queries" />
            </ContextMedicalReviewForm>
        </>
    );
};

export default ScreeningBloodCollection;
